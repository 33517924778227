import React from 'react';
import { Typography, Box, useTheme, Button } from "@mui/material";
import { tokens } from "../../theme/theme.js";
import AddIcon from '@mui/icons-material/Add';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import EditIcon from '@mui/icons-material/Edit';


const Header = ({ title, subtitle, buttonText,secondButtonText, buttonAction,secondButtonAction, buttonStyle, buttonIcon='add', secondButtonIcon='add' }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Box mb="30px">
      <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <div>
        <Typography
          variant="h2"
          color={colors.grey[100]}
          fontWeight="bold"
          sx={{ m: "0 0 5px 0" }}
        >
          {title}
        </Typography>
        </div>
        <div style={{display: 'flex', justifyContent: 'space-between', gap: '1rem'}}>
          
        {secondButtonText &&
           <Box>
           <Button
            onClick={secondButtonAction}
             sx={{
               backgroundColor: colors.blueAccent[700],
               color: colors.grey[100],
               fontSize: "14px",
               fontWeight: "bold",
               padding: "10px 20px",
               ...buttonStyle
             }}
           >
            {secondButtonIcon === 'add' && 
              <AddIcon sx={{ mr: "10px" }}/>
            }
            {secondButtonIcon === 'download' && 
              <FileDownloadIcon sx={{ mr: "10px" }} />
            }
            {secondButtonIcon === 'update' && 
              <EditIcon sx={{ mr: "10px" }}/>
            }
             {secondButtonText}
           </Button>
         </Box>
        }
        {buttonText &&
           <Box>
           <Button
            onClick={buttonAction}
             sx={{
               backgroundColor: colors.blueAccent[700],
               color: colors.grey[100],
               fontSize: "14px",
               fontWeight: "bold",
               padding: "10px 20px",
               ...buttonStyle
             }}
           >
            {buttonIcon === 'add' && 
              <AddIcon sx={{ mr: "10px" }}/>
            }
            {buttonIcon === 'download' && 
              <FileDownloadIcon sx={{ mr: "10px" }} />
            }
            {buttonIcon === 'update' && 
              <EditIcon sx={{ mr: "10px" }}/>
            }
             {buttonText}
           </Button>
         </Box>
        }
        
        </div>
      
      </div>
      <Typography variant="h5" color={colors.greenAccent[400]}>
        {subtitle}
      </Typography>
    </Box>
  );
};

export default Header;
