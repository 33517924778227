import React from 'react';
import {
    Box,
    Button,
    Checkbox,
    FormControl,
    Grid,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select,
    useTheme,
    CircularProgress,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { getDatFromColumn } from "../../common/utils";
import EnhancedTable from "../../components/shared/EnhancesTable";
import Calendar from "../../components/shared/calendar/DateRange";
import { DOCUMENT_TYPES, DOCUMENT_TYPES_BUILDER } from "../../common/constants"
import { tokens } from "../../theme/theme";
import moment from 'moment';


const DocumentationBody = ({
    downloads,
    options,
    setOptions,
    users=[],
    isLoading = false,
    cars=[],
    filters,
    setFilters,
    setToast,
    downloadDocument
}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const OTP_COLUMNS = [
        { field: "id", headerName: "ID", flex: 0.5 },

        {
            field: "user",
            headerName: "Administrador",
            flex: 1,
            renderCell: (params) => {
                const thisRow = getDatFromColumn(params);
                return (
                  thisRow.user.user.name+' '+thisRow.user.user.surnames)
            }
        },
        {
            field: "dateFrom",
            headerName: "Rango de fecha",
            flex: 1,
            renderCell: (params) => {
              const thisRow = getDatFromColumn(params);
              return `${moment(thisRow.dateFrom.dateFrom).format("DD/MM/YYYY")} - ${moment(thisRow.dateFrom.dateTo).format("DD/MM/YYYY")}`;
            },
        },
        {
          field: "usersIds",
          headerName: "Nº Usuarios",
          flex: 1,
          renderCell: (params) => {
            const thisRow = getDatFromColumn(params);
            const usersarray = thisRow.usersIds.usersIds.split(",");
            return usersarray.length;
          },
        },
        {
            field: "documentType",
            headerName: "Tipo de Documento",
            flex: 1,
            renderCell: (params) => {
              const thisRow = getDatFromColumn(params);
              const usersarray = DOCUMENT_TYPES_BUILDER.filter(data =>  data.value === thisRow.documentType.documentType).pop().title;
              console.log(usersarray)
              return usersarray;
            },
          },
        {
          field: "date",
          headerName: "Fecha Descarga",
          flex: 1,
          renderCell: (params) => {
            const thisRow = getDatFromColumn(params);
            return moment(thisRow.date.date).format("DD/MM/YYYY - HH:mm");
          },
        },
        {
          field: "status",
          headerName: "Estado",
          flex: 1,
          renderCell: (params) => {
            const thisRow = getDatFromColumn(params);
            return thisRow.status.status === 1
              ? "Descargado"
              : thisRow.status.status === 0
              ? "Pendiente"
              : "Error";
          },
        },
      ];
    
    const onChangeCalendar = (event) => {
        setFilters({ ...filters, rangeDate: event})
    }

    const onChangeDocumentType = (event) => {
        setFilters({ ...filters, documentType: event.target.value})
    }
    const onChangeCar = (event) => {
        setFilters({ ...filters, carSelected: event.target.value})
    }
    const onChangeUsers = (event) => {
      const {  target: { value } } = event;

      if(value.length <= 5){
        setFilters({ ...filters, userSelected: typeof value === 'string' ? value.split(',') : value } );   
      }else {
        setToast({
          open: false,
          message: "Solo se pueden seleccionar hasta 5 usuarios por descarga..",
          severity: "error",
        })
      }
    }

    return (
        <>
        <Grid container spacing={3} style={{ marginLeft: '0.1rem' }}>
            <Grid item xs={2}>
                Tipo de documentos
                <FormControl
                    fullWidth
                    sx={{ mt: 1, minWidth: 120 }}
                >
                    <Select 
                        value={filters.documentType}
                        onChange={onChangeDocumentType}
                    >
                        {              
                            DOCUMENT_TYPES_BUILDER.map((documentType) => (
                                <MenuItem value={documentType.value}>{`${documentType.title}`}</MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={2}>
                <Calendar onChange={onChangeCalendar} />
            </Grid>
            {filters.documentType === DOCUMENT_TYPES.REPORT_WORKER_DAY &&
                <Grid item xs={2}>
                    Usuarios
                    <FormControl
                        fullWidth
                        variant="standard"
                        sx={{ mt: 1, minWidth: 120 }}
                    >
                        <Select
                            multiple
                            value={filters.userSelected}
                            input={<OutlinedInput />}
                            onChange={onChangeUsers}
                            renderValue={(selected) => selected.join(",")}
                        >
                        {users && users.length &&
                            users.map((user) => (
                            <MenuItem key={user.id} value={user.id}>
                                <Checkbox 
                                    checked={filters.userSelected.indexOf(user.id) > -1}  
                                />
                                <ListItemText primary={`${user.name} ${user.surnames}`} />
                            </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            }
            {filters.documentType === DOCUMENT_TYPES.REPORT_TRACKER_VEHICLE &&
                <Grid item xs={2}>
                    Vehículo
                    <FormControl
                        fullWidth
                        variant="standard"
                        sx={{ mt: 1, minWidth: 120 }}
                    >
                        <Select
                            value={filters.carSelected}
                            input={<OutlinedInput />}
                            onChange={onChangeCar}
                        >
                        {cars.length > 0 && cars.map(car => (
                        <MenuItem key={car.id} value={car.id} >{`${car.license_plate} - ${car.brand} ${car.model}`}</MenuItem>
                        ))} 
                        </Select>
                    </FormControl>
                </Grid>
            }
            <Grid item xs={3}>
                <Button
                    onClick={() => downloadDocument()}
                    sx={{
                    backgroundColor: colors.blueAccent[700],
                    color: colors.grey[100],
                    fontSize: "14px",
                    fontWeight: "bold",
                    marginTop: "2rem",
                    padding: "10px 20px",
                    }}
                    disabled={isLoading}
                >
                    {isLoading ? (
                    <>
                    <CircularProgress size={20} sx={{ mr: "10px" }} />
                    </>
                    ) : (
                    <>
                        <AddIcon sx={{ mr: "10px" }} />
                        Generar Documento
                    </>
                    )}
                </Button>
            </Grid>
        </Grid>
        <Box m="20px">
        {downloads?.data && (
          <>
            <EnhancedTable
              data={downloads.data}
              header={OTP_COLUMNS}
              options={options}
              setOptions={setOptions}
              totalRow={downloads.totalRowCount}
              checkboxSelection
            />
          </>
        )}
      </Box>
      </>
    )
}

export default DocumentationBody