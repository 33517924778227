import * as React from 'react';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';

const  EnhancedToast = ({ toast, setToast })  => {

  React.useEffect(() => {
    if(toast.open){
        setTimeout(() => {
            setToast({...toast, open: false});
        }, 5000);
    }
    if(!toast.open){
        setToast({...toast, open: false});
    }
  }, [toast.open]);

  return (
    <div id='toast-container' style={{ 
        width: 'auto',
        position: 'fixed',
        top: 0,
        right: 0,
        padding: '0.5rem',
        marginTop: '1rem',
        zIndex: 1400
    }}>
      <Collapse in={toast.open}>
        <Alert
          severity={toast.severity}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setToast({...toast, open: false});
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2, fontSize: '1rem' }}
        >
          {toast.message}
        </Alert>
      </Collapse>
    </div>
  );
}

export default EnhancedToast;
