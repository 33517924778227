import React from 'react';
import { INITIAL_OTP_VALUE, CREATE_OTP, INITIAL_OTP_VALUE_ERROR } from '../../common/constants';
import { CheckOtpRequiredFields } from '../../common/utils';
import Panel from '../../components/shared/Panel';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import OtpForm from './OtpForm';


const OtpCreate = ({ openPanel, closePanel , setToast, refetch}) => {
    const axiosPrivate = useAxiosPrivate();
    const [errors, setErrors] = React.useState(INITIAL_OTP_VALUE_ERROR);
    const [otpMapper, setOtpMapper] = React.useState(INITIAL_OTP_VALUE);


    const createOtp = async () => {
        
        const checkErrors = CheckOtpRequiredFields(otpMapper);
        if (checkErrors.errorCounter === 0) {
            await axiosPrivate.post(
                CREATE_OTP,
                JSON.stringify({ otpMapper }),
                {
                    headers: { "Content-Type": "application/json" },
                    withCredentials: true,
                }
            ).then(() => {
                refetch()
                setToast({
                    open: true,
                    message: "OTP Creada con éxito",
                    severity: "success",
                })
            }).catch(() => {
                setToast({
                    open: true,
                    message: "Error creando OTP intentelo de nuevo más tarde.",
                    severity: "error",
                })
            });
            closePanel(false)
            setErrors(INITIAL_OTP_VALUE_ERROR)
        } else {
            setErrors(checkErrors.otpErrors)
        }
    }


    return (
        <Panel
            title={"Añadir OTP"}
            open={openPanel}
            bodyStyle={{ overflow: "hidden" }}
            bodyText={<OtpForm otpData={otpMapper} setOtpData={setOtpMapper} errors={errors} />}
            buttonAccept={"aceptar"}
            acceptAction={() => createOtp()}
            requestClose={() => { setErrors(INITIAL_OTP_VALUE_ERROR); setOtpMapper(INITIAL_OTP_VALUE); closePanel(false); } }

        />
    )
};

export default OtpCreate;