import {
  Box,
  FormControl,
  InputLabel,
  Select,
  TextField,
  MenuItem,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import moment from "moment";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import EnhancedTimePicker from "../../components/shared/EnhancedTimePicker";

const SigningForm = ({
  signingData,
  setSigningData,
  errors = {},
  otps,
  activeAllFields = false,
  users = [],
}) => (
  <Box m="20px">
    <form>
      <Box
        display="grid"
        gap="30px"
        gridTemplateColumns="repeat(4, minmax(0, 1fr))"
      >
        {!activeAllFields && (
          <TextField
            fullWidth
            type="text"
            label="Identificador"
            value={signingData?.id}
            name="id"
            error={!!errors.id}
            helperText={errors.id}
            disabled
            sx={{ gridColumn: "span 2" }}
          />
        )}
        {!activeAllFields && (
          <TextField
            fullWidth
            type="text"
            label="Usuario"
            disabled={!activeAllFields}
            value={signingData?.user?.name}
            name="surnames"
            error={!!errors.surnames}
            helperText={errors.surnames}
            sx={{ gridColumn: "span 2" }}
          />
        )}
        <TextField
          fullWidth
          type="date"
          label="Fecha"
          disabled={!activeAllFields}
          value={moment(signingData?.date).format("YYYY-MM-DD")}
          onChange={(event) =>
            setSigningData({ ...signingData, date: event.target.value })
          }
          name="contractNumber"
          error={!!errors.contractNumber}
          helperText={errors.contractNumber}
          sx={{ gridColumn: "span 2" }}
        />
        <DemoContainer components={["TimePicker"]} sx={{ gridColumn: "span 2" , paddingTop:"0" }}>
          <EnhancedTimePicker
            label="Horas"
            signingData={signingData}
            setSigningData={setSigningData}
            value={signingData?.hours}
            error={!!errors.hours}
            helperText={errors.hours}
            sx={{ gridColumn: "span 1" , marginBottom: "1rem", paddingTop:"0" }}
            timeSteps={{ hours: 1, minutes: 15,}}
            maxTime={moment().startOf('day').add(8,'hours')}
            ampm={false}
          ></EnhancedTimePicker>
        </DemoContainer>
        
        {activeAllFields && (
          <FormControl fullWidth sx={{ gridColumn: "span 2" }}>
            <InputLabel id="demo-simple-select-standard-label">
              Usuarios
            </InputLabel>
            <Select
              label="Usuarios"
              error={!!errors.surnames}
              helperText={errors.surnames}
              value={signingData?.userId}
              onChange={(event) =>
                setSigningData({ ...signingData, userId: event.target.value })
              }
            >
              {users.length > 0 &&
                users.map((user) => (
                  <MenuItem key={user.id} value={user.id}>
                    {`${user.name}`} {`${user.surnames}`}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        )}
        <FormControl fullWidth sx={{ gridColumn: activeAllFields ? "span 2" : "span 2" }}>
          <InputLabel id="demo-simple-select-standard-label">OTPS</InputLabel>
          <Select
            label="OTPS"
            error={!!errors.surnames}
            helperText={errors.surnames}
            value={signingData?.otpId}
            onChange={(event) =>
              setSigningData({ ...signingData, otpId: event.target.value })
            }
          >
            {otps &&
              otps.map((otp) => (
                <MenuItem key={otp.id} value={otp.id}>{`${otp.name}`}</MenuItem>
              ))}
          </Select>
        </FormControl>

        <TextField
          fullWidth
          type="text"
          label="Descripción"
          onChange={(event) =>
            setSigningData({ ...signingData, description: event.target.value })
          }
          value={signingData?.description}
          name="workState"
          error={!!errors.description}
          helperText={errors.description}
          sx={{ gridColumn: activeAllFields ? "span 4" : "span 2" }}
        />

        <FormControlLabel
          control={
            <Checkbox
              checked={signingData.concepts.ACS_U_PL_DESPLAZAM}
              onChange={(ev) =>
                setSigningData({
                  ...signingData,
                  concepts: {
                    ...signingData.concepts,
                    ACS_U_PL_DESPLAZAM: ev.target.checked,
                    VACACIONES: false,
                    ACS_HORAS_ENF_PT: false,
                    ACS_U_PLUS_AY_MANU: false,
                  },
                })
              }
              name="antoine"
            />
          }
          label="Plus desplazamiento"
        />

        <FormControlLabel
          control={
            <Checkbox
              checked={signingData.concepts.ACS_U_MDIA_DIETA}
              onChange={(ev) =>
                setSigningData({
                  ...signingData,
                  concepts: {
                    ...signingData.concepts,
                    ACS_U_MDIA_DIETA: ev.target.checked,
                    VACACIONES: false,
                    ACS_HORAS_ENF_PT: false,
                    ACS_U_PLUS_AY_MANU: false,
                  },
                })
              }
              name="antoine"
            />
          }
          label="Plus media dieta"
        />

        <FormControlLabel
          control={
            <Checkbox
              checked={signingData.concepts.ACS_U_PLUS_AY_MANU}
              onChange={(ev) =>
                setSigningData({
                  ...signingData,
                  concepts: {
                    ...signingData.concepts,
                    ACS_U_PLUS_AY_MANU: ev.target.checked,
                    ACS_U_PL_DESPLAZAM: false,
                    VACACIONES: false,
                    ACS_HORAS_ENF_PT: false,
                    ACS_U_MDIA_DIETA: false,
                  },
                })
              }
              name="antoine"
            />
          }
          label="Plus ayuda manutención"
        />

        <FormControlLabel
          control={
            <Checkbox
              checked={signingData.concepts.VACACIONES}
              onChange={(ev) =>
                setSigningData({
                  ...signingData,
                  concepts: {
                    ...signingData.concepts,
                    VACACIONES: ev.target.checked,
                    ACS_HORAS_ENF_PT: false,
                    ACS_U_PL_DESPLAZAM: false,
                    ACS_U_MDIA_DIETA: false,
                    ACS_U_PLUS_AY_MANU: false,
                  },
                })
              }
              name="antoine"
            />
          }
          label="Vacaciones"
        />

        <FormControlLabel
          control={
            <Checkbox
              checked={signingData.concepts.ACS_HORAS_ENF_PT}
              onChange={(ev) =>
                setSigningData({
                  ...signingData,
                  concepts: {
                    ...signingData.concepts,
                    ACS_HORAS_ENF_PT: ev.target.checked,
                    VACACIONES: false,
                    ACS_U_PL_DESPLAZAM: false,
                    ACS_U_MDIA_DIETA: false,
                    ACS_U_PLUS_AY_MANU: false,
                  },
                })
              }
              name="antoine"
            />
          }
          label="Baja por enfermedad"
        />
      </Box>
    </form>
  </Box>
);

export default SigningForm;
