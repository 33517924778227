import React from "react";
import "../../theme/loader.css";

const LoadingSection = () => {
  return (
    <div style={{ width: "100%", height: '100%' }}>
      <div id="main-loader">
        <div id="loader"></div>
      </div>
    </div>
  );
};

export default LoadingSection;
