import { Box, IconButton, useTheme } from "@mui/material";
import { useContext } from "react";
import { ColorModeContext, tokens } from "../../theme/theme.js";
import { LOGOUT_URL } from "../../common/constants.js";
import axios from "../../api/axios.js";
import useAuth from "../../hooks/useAuth.js";
import logo from '../../assets/logo_minim.png'
const Topbar = ({ hideSearch = false, hideUser = false, hideLogo = true }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  const { setAuth } = useAuth();
  const loogut = async () =>{
    await axios.post(
      LOGOUT_URL,
      {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      }
    );
    setAuth({})
  }

  return (
    <Box
      sx={{borderBottom: `1px solid ${colors.grey[900]}`}}
      display="flex"
      justifyContent="space-between"
      p={2}
    >
        <a href='/'>
          <img style={{ width: '3rem', height: '3rem', marginBottom: '0rem'}} src={logo} />
        </a>
        <p style={{ fontSize: '1.25rem', margin: '0.5rem 0px 0px 0px', padding: '0px' }} >Gramalleira - Gestión de Horas</p>
    </Box>
  );
};

export default Topbar;
