import React from 'react';
import { CREATE_USER, INITIAL_USER_VALUE, INITIAL_USER_VALUE_ERROR } from '../../common/constants';
import { CheckUserRequiredFields } from '../../common/utils';
import Panel from '../../components/shared/Panel';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import UserForm from './userForm';


const UserCreate = ({openPanel, closePanel, setToast, refetch}) => {
    const axiosPrivate = useAxiosPrivate();
    const [errors, serErrors] = React.useState(INITIAL_USER_VALUE_ERROR);
    const [userMapper, setUserMapper] = React.useState(INITIAL_USER_VALUE);


    const createUser = async () => {
        const checkErrors = CheckUserRequiredFields(userMapper);
        if(checkErrors.errorCounter === 0){
            await axiosPrivate.post(
                CREATE_USER,
                JSON.stringify({ userMapper }),
                {
                    headers: { "Content-Type": "application/json" },
                    withCredentials: true,
                }
            ).then(() => {
                refetch();
                setUserMapper(INITIAL_USER_VALUE)
                setToast({
                    open: true,
                    message: "Usuario creado con éxito",
                    severity: "success",
                })   
            })
            .catch(() => {
                setToast({
                    open: true,
                    message: "Error creando usuario, intentelo de nuevo.",
                    severity: "error",
                });        
            });
            closePanel(false);
            serErrors(INITIAL_USER_VALUE_ERROR); 
            
        }else{
            console.log('errors', errors);
            setToast({
                open: true,
                message: "Revisa los datos del formulario.",
                severity: "error",
            }); 
            serErrors(checkErrors.userErrors);
        }
    }

    return (
        <Panel
        title={"Añadir usuario"}
        open={openPanel}
        bodyStyle={{ overflow: "hidden" }}
        bodyText={<UserForm userData={userMapper} setUserData={setUserMapper} errors={errors}/>}
        buttonAccept={"aceptar"}
        acceptAction={() => createUser()}
        requestClose={() => {serErrors(INITIAL_USER_VALUE_ERROR); setUserMapper(INITIAL_USER_VALUE); closePanel(false); } }
      />
    )
};

export default UserCreate;