import React, { useEffect, useRef, cloneElement, useState } from 'react';
import { DateRange } from 'react-date-range'
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { format } from 'date-fns';
import { TextField } from '@mui/material';
import moment from 'moment';


const Calendar = ({ onChange }) => {
  const [openDate, setOpenDate] = useState(false)
  const [date, setDate] = useState([
    {
      startDate: new Date(moment().startOf('day')),
      endDate: new Date(moment().endOf('day')),
      key: 'selection',
    }
  ]);


  React.useEffect(() => {
    onChange(date);
  }, [date]);


  return (
    <React.Fragment>
    Fecha
    <div style={{ marginTop: '0.5rem', zIndex: 2 }}>
      <TextField 
        onClick={ ev =>{setOpenDate(!openDate);} }
        value={`${format(date[0].startDate, "dd/MM/yyyy")} - ${format(date[0].endDate, "dd/MM/yyy") }` }
        placeholder="Date" 
        class="textbox-n" 
        type="text" 
        onblur="(this.type='text')" 
        id="date" 
      />      
      { openDate &&
        <div className='calendar' style={{zIndex: 2, position: ''}}>
            <DateRange 
              editableDateInputs={ true } 
              onChange={ (item) => setDate([item.selection]) } 
              moveRangeOnFirstSelection={ false } 
              ranges={ date } 
              shouldCloseOnSelect={true}
              className="date" />
        </div>
      }
    </div>
    </React.Fragment>
  )
}

export default Calendar;
