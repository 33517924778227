import React from "react";
import { Box, IconButton, TextField } from "@mui/material";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import LogedLayout from "../../components/shared/LogedLayout";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import Header from "../../components/shared/Header";
import { GETUSERS_URL, GET_USER_LINK_PASWD, ROLES_BUILDER, TOGLE_ACTIVE_USER } from "../../common/constants.js";
import LoadingSection from "../../components/shared/LoadingSection.js";
import EnhancedTable from "../../components/shared/EnhancesTable";
import { Dropdown, DropdownMenuItem } from "../../components/shared/Dropdown";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import UserUpdate from "./userUpdate";
import { getDatFromColumn, zfill } from "../../common/utils";
import UserCreate from "./userCreate";
import EnhancedToast from "../../components/shared/enhancesToast";
import EnhancedModal from "../../components/shared/EnhancedModal";


const UsersPage = () => {
  const axiosPrivate = useAxiosPrivate();
  const [data, setData] = React.useState({ loading: true });
  const [editPanel, setEditPanel] = React.useState(false);
  const [createPanel, setCreatePanel] = React.useState(false);
  const [activeAction, setActiveAction] = React.useState(false);
  const [linkPasswdAction, setLinkPasswdAction] = React.useState(false);
  const [link, setLink] = React.useState('Error al copiar el enlace!');

  const [toast, setToast] = React.useState({
    open: false,
    message: "",
    severity: "success",
  })

  const USER_COLUMN = [
      { field: "id", headerName: "ID", flex: 0.5, renderCell: (params) => {
        const thisRow = getDatFromColumn(params);
        return (
          zfill(thisRow.id.id, 6)
        )
      } },
      {
        field: "name",
        headerName: "Nombre",
        flex: 1,
        cellClassName: "name-column--cell",
      },
      {
        field: "surnames",
        headerName: "Apellidos",
        flex: 1,
      },
      {
        field: "email",
        headerName: "Correo Electrónico",
        flex: 1,
      },
      {
        field: "roleId",
        headerName: "Rol",
        flex: 1,
        renderCell: (params) => {
          const thisRow = getDatFromColumn(params);
          return (
            ROLES_BUILDER[thisRow.roleId.roleId-1]
          )
        }
      },
      {
        field: "active",
        headerName: "Estado",
        flex: 1,
        renderCell: (params) => {
          const thisRow = getDatFromColumn(params);
          return (
            thisRow.active.active ? 'Activado' : 'Desactivado'
          )
        }
      },
      {
        field: "actions",
        headerName: "Acciones",
        flex: 1,
        renderCell: (params) => {
          const thisRow = getDatFromColumn(params).id;
          const onClickEdit = e => {
            setEditPanel(thisRow)
            e.stopPropagation(); // don't select this row after clicking
          };
          const onClickToogleActie = e => {
            setActiveAction(thisRow)
            e.stopPropagation(); // don't select this row after clicking
          }
          const onClickPasswd = e => {
            setLinkPasswdAction(thisRow.id)
            e.stopPropagation(); // don't select this row after clicking
          }

          return (
            <Dropdown
              trigger={
                <IconButton>
                  <MoreVertIcon sx={{fontSize: '1.25rem' }}/>
                </IconButton>
              }
              menu={[
                <DropdownMenuItem onClick={onClickEdit}>Editar</DropdownMenuItem>,
                <DropdownMenuItem onClick={onClickToogleActie}>{params.row.active ? 'Desactivar' : 'Activar'}</DropdownMenuItem>,
                <DropdownMenuItem onClick={onClickPasswd}>Cambiar contraseña</DropdownMenuItem>,

              ]}
            />
          );
        },
      }
    ];

  const [options,setOptions]=React.useState({
    takeValue:10,
    skipValue:0,
    rowsPerPage:10,
  });
  

  const getUsers = React.useCallback(async () => {
    const { takeValue,skipValue } = options
    const response = await axiosPrivate.post(GETUSERS_URL,
      JSON.stringify({takeValue,skipValue}),
      {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      }
    );
    if (response) {
      setData({ loading: false, data: response.data.data, totalRowCount: response.data.totalRowCount });
    }
  }, [axiosPrivate, options])

  const getUserLink = async userId => {
    const response = await axiosPrivate.post(GET_USER_LINK_PASWD, 
    JSON.stringify({ userId }),  
    {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    if(response){
      setLink(response.data.data)
    }
  }
  
  React.useEffect(() => {
    if(linkPasswdAction){
      getUserLink(linkPasswdAction)
    }
  }, [linkPasswdAction])
  
  const toogleActiveUser = async () => {
    await axiosPrivate.post(
      TOGLE_ACTIVE_USER,
      JSON.stringify({ activeAction }),
      {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
      }
  ).then(() => {
      setToast({
          open: true,
          message: "Usario activada/desactivada con éxito",
          severity: "success",
      })
      getUsers()
  }).catch(() => {
      setToast({
          open: true,
          message: "Error activando/desactivando Usuario intentelo de nuevo más tarde.",
          severity: "error",
      })
      getUsers()
  });
    setActiveAction(false);
    
  }

  const copyLink = () => {
    navigator.clipboard.writeText(link)
    setToast({
      open: true,
      message: "Enlace copiado con éxito.",
      severity: "success",
  })
  }


  React.useEffect(() => {
    getUsers();
  }, [getUsers]);

  if (data.loading) {
    return <LoadingSection />;
  }
  return (
    <LogedLayout>
      <Box m="20px">
        <Header 
          title="Usuarios" 
          subtitle="Listado de Usuarios"  
          buttonText={'Añadir usuario'} 
          buttonAction={() => setCreatePanel(true)}
        />
        {data && 
        <EnhancedTable
          data={data.data}
          options={options}
          setOptions={setOptions}
          header={USER_COLUMN}
          checkboxSelection
          totalRow={data.totalRowCount}
          onRowClick={() => setEditPanel(true)}
        />
        }
      </Box>
      <UserUpdate user={editPanel} setToast={setToast} refetch={getUsers} />
      <UserCreate openPanel={createPanel} closePanel={setCreatePanel} setToast={setToast}  refetch={getUsers}/>

      <EnhancedToast toast={toast} setToast={setToast}/>
      <EnhancedModal 
        closeAction={() => setActiveAction(false)} 
        acceptAction={() => toogleActiveUser()} 
        closeButton={'Cancelar'}
        acceptButton={'Aceptar'}
        title={'Gestión usuario'}
        body={'Está acción activara/desactivará el siguiente usuario, ¿Quiere continuar?'} 
        open={!!activeAction}
      />
       <EnhancedModal 
        closeAction={() => setLinkPasswdAction(false)} 
        closeButton={'Cerrar'}
        title={'Gestión usuario - Contraseñas'}
        body={<>
          <p>Enlace para restablecer la contraseña</p>
          <div style={{ display: 'flex', direction: 'row'}}>
          <TextField
                onClick={() => copyLink()}
                fullWidth
                type="text"
                value={link}
                name="email"
                disabled
                sx={{ gridColumn: "span 4" }}
              />
          <IconButton aria-label="delete"   onClick={() => copyLink()}>
            <ContentCopyIcon/>
          </IconButton>
          </div>

        </>} 
        open={!!linkPasswdAction}
      />
    </LogedLayout>
  );




};

export default UsersPage;
