import React from "react";
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { Box, Button, Checkbox, FormControl, Grid, ListItemText, MenuItem, OutlinedInput, Select, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme/theme.js";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import EmailIcon from "@mui/icons-material/Email";
import Header from "../../components/shared/Header"; 
import LineChart from "../../components/shared/LineChart";
import StatBox from "../../components/shared/StatBox";
import LogedLayout from "../../components/shared/LogedLayout.js";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import { WorkHistory } from "@mui/icons-material";
import moment from "moment";
import { saveAs } from 'file-saver'


import { DASHBOARD_FIRST_ROW, GET_ALL_OTPS, GET_LAST_SIGNING, GET_OTP_LINE, } from "../../common/constants";

const Dashboard = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const axiosPrivate = useAxiosPrivate();
  const [firstRow, setFirstRow] = React.useState([])
  const [otpLineData, setOtpLineData] = React.useState([]);
  const [lastSigningData, setLastSigningData] = React.useState([]);
  const [otps, setOtps] = React.useState([]);
  const [otpsSelected, setOtpsSelected] = React.useState([])


  const handleOtpFilterChange = (event) => {
    const {
      target: { value },
    } = event;
    if(value.length <= 10){
      setOtpsSelected(
        // On autofill we get a stringified value.
        typeof value === 'string' ? value.split(',') : value,
      );    }
  
  };

  const getLineOtpGraph =  React.useCallback( async () => {
    const response = await axiosPrivate.post(GET_OTP_LINE,  JSON.stringify({ 
      otps: otpsSelected,
    }),);
    if(response && response.data?.data){
      setOtpLineData(response.data?.data)
    }
  });


  const getOTPS = React.useCallback( async () => {
    const response = await axiosPrivate.get(GET_ALL_OTPS);
    if(response.status === 201) {
      setOtps(response.data.data)
    }
  });

  const getFirstRow = React.useCallback( async () => {
    const response = await axiosPrivate.get(DASHBOARD_FIRST_ROW)
    if(response.status === 201){
      setFirstRow(response.data)
    }
  })

  const getLastSigningData = React.useCallback( async () => {
    const response = await axiosPrivate.get(GET_LAST_SIGNING);
    if(response && response.data?.data){
      setLastSigningData(response.data?.data)
    }
  });

  React.useEffect(() => {
    getFirstRow();
    getLineOtpGraph();
    getLastSigningData();
    getOTPS();
  }, []); 

  React.useEffect(() => {
    getLineOtpGraph();
  }, [otpsSelected]);

  React.useEffect(() => {
    if(otpsSelected.length === 0 && otps.length > 0){
      const tmpOtpSelected = []
      otps.slice(0, 10).map(otp => {
        if(otp.id){
          tmpOtpSelected.push(otp.id)
        }
        return tmpOtpSelected
      })
      setOtpsSelected(tmpOtpSelected)
    }
  }, [otps])
  return (
    <LogedLayout>
      <Box m="20px">
        {/* HEADER */}
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Header title="DASHBOARD" subtitle="Bienvenido/a a Gramalleira" />
        </Box>

        {/* GRID & CHARTS */}
        <Box
          display="grid"
          gridRow="span 1"
          gridTemplateColumns="repeat(12, 1fr)"
          gridAutoRows="140px"
          gap="20px"
        >
          {/* ROW 1 */}

          <Box
            gridColumn="span 3"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <StatBox
              subtitle= "Número de Usuarios"
              increase= { firstRow.Usuarios || 0 } 
              icon={
                <EmailIcon
                  sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                />
              }
            />
          </Box>
          <Box
            gridColumn="span 3"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <StatBox
              //title= { firstRow.OTPS }
              subtitle="Numero de OTS"
              //progress="0.80"
              increase= { firstRow.OTPS }
              icon={ 
                <ReceiptOutlinedIcon
                  sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                />
              }
            />
          </Box>

          <Box
            gridColumn="span 3"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <StatBox
              //title="12,361"
              subtitle="Horas mes actual"
              //progress="0.75"
              increase={ firstRow.HorasMesActual }
              icon={
                <WorkHistory
                  sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                />
              }
            />
          </Box>
          <Box
            gridColumn="span 3"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <StatBox
              //title="431,225"
              subtitle="Horas mes anterior"
              //progress="0.50"
              increase={ firstRow.HorasMesAnterior }
              icon={
                <WorkHistory
                  sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                />
              }
            />
          </Box>
          

          {/* ROW 2 */}
          <Box
            gridColumn="span 12"
            gridRow="span 4"
            backgroundColor={colors.primary[400]}
          >
            <Box
              mt="25px"
              p="0 30px"
              display="flex "
              justifyContent="space-between"
              alignItems="center"
            >
              <Box>
                <Typography
                  variant="h5"
                  fontWeight="600"
                  color={colors.grey[100]}
                >
                  Reporte horas sobre los OTPS
                </Typography>
              </Box>
              <Box
              mt="25px"
              p="0 30px"
              display="flex "
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item xs={2}>
                OTP Seleccionadas
                <FormControl fullWidth variant="standard" sx={{ mt: 1, minWidth: 120 }}>
                  <Select
                    multiple
                    value={otpsSelected}
                    input={<OutlinedInput/>}
                    onChange={handleOtpFilterChange}
                    renderValue={(selected) => selected.join(',')}
                    >
                      {otps.map(otp => (
                        <MenuItem key={otp.id} value={otp.id}>
                          <Checkbox checked={otpsSelected.includes(otp.id)} />
                          <ListItemText primary={otp.name} />
                        </MenuItem>
                      ))}
                  </Select>
                  </FormControl>
              </Grid>
            </Box>
              
            </Box>
          
            <Box height="450px" m="-20px 0 0 0">
              <LineChart isDashboard={true} data={otpLineData} />
            </Box>
          </Box>


          {/* ROW 3 */}
          <Box
            gridColumn="span 12"
            gridRow="span 2"
            backgroundColor={colors.primary[400]}
            overflow="auto"
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              borderBottom={`4px solid ${colors.primary[500]}`}
              colors={colors.grey[100]}
              p="15px"
            >
              <Typography
                color={colors.grey[100]}
                variant="h5"
                fontWeight="600"
              >
               Últimas imputaciones
              </Typography>
            </Box>
            {lastSigningData.map((transaction, i) => (
              <Box
                key={`${transaction.id}-${i}`}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                borderBottom={`4px solid ${colors.primary[500]}`}
                p="15px"
              >
                <Box>
                  <Typography
                    color={colors.greenAccent[500]}
                    variant="h5"
                    fontWeight="600"
                  >
                    {transaction.user.name}
                  </Typography>
                  <Typography color={colors.grey[100]}>
                    {transaction.user.surnames}
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    color={colors.greenAccent[500]}
                    variant="h5"
                    fontWeight="600"
                  >
                    {transaction.otp.name}
                  </Typography>
                  <Typography color={colors.grey[100]}>
                    {transaction.otp.description}
                  </Typography>
                </Box>
                <Box color={colors.grey[100]}> {moment(transaction?.date).format('DD/MM/YYYY')}</Box>
                <Box
                  backgroundColor={colors.greenAccent[500]}
                  p="5px 10px"
                  borderRadius="4px"
                >
                  {transaction.hours}h
                </Box>
              </Box>
            ))}
          </Box>  
        </Box>
      </Box>
    </LogedLayout>
  );
};

export default Dashboard;
