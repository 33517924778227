import React from "react";
import { Box, IconButton, TextField } from "@mui/material";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import LogedLayout from "../../components/shared/LogedLayout";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import Header from "../../components/shared/Header";
import { CAR_TYPES_VALUES, TOGLE_ACTIVE_CAR, GET_PAGINATED_CARS } from "../../common/constants.js";
import LoadingSection from "../../components/shared/LoadingSection.js";
import EnhancedTable from "../../components/shared/EnhancesTable";
import { Dropdown, DropdownMenuItem } from "../../components/shared/Dropdown";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { getDatFromColumn, zfill } from "../../common/utils";
import EnhancedToast from "../../components/shared/enhancesToast";
import EnhancedModal from "../../components/shared/EnhancedModal";
import CarCreate from "./CarCreate";
import CarUpdate from "./CarUpdate";


const Car = () => {
  const axiosPrivate = useAxiosPrivate();
  const [data, setData] = React.useState({ loading: true });
  const [editPanel, setEditPanel] = React.useState(false);
  const [createPanel, setCreatePanel] = React.useState(false);
  const [activeAction, setActiveAction] = React.useState(false);

  const [toast, setToast] = React.useState({
    open: false,
    message: "",
    severity: "success",
  })

  const USER_COLUMN = [
      { 
        field: "id",
        headerName: "ID",
        flex: 0.5
      },
      {
        field: "license_plate",
        headerName: "Matricula",
        flex: 1,
        cellClassName: "name-column--cell",
      },
      {
        field: "brand",
        headerName: "Marca",
        flex: 1,
      },
      {
        field: "model",
        headerName: "Modelo",
        flex: 1,
      },
      {
        field: "company",
        headerName: "Compañia",
        flex: 1,
        renderCell: (params) => {
          const thisRow = getDatFromColumn(params);
          return (
            CAR_TYPES_VALUES[thisRow.company.company]
          )
        }
      },
      {
        field: "user",
        headerName: "Usuario",
        flex: 1.5,
        renderCell: (params) => {
            const thisRow = getDatFromColumn(params);
            return (
              thisRow.user.user.name+' '+thisRow.user.user.surnames)
        }
      },
      {
        field: "active",
        headerName: "Estado",
        flex: 1,
        renderCell: (params) => {
          const thisRow = getDatFromColumn(params);
          return (
            thisRow.active.active ? 'Activado' : 'Desactivado'
          )
        }
      },
      {
        field: "actions",
        headerName: "Acciones",
        flex: 1,
        renderCell: (params) => {
          const thisRow = getDatFromColumn(params).id;
          const onClickEdit = e => {
            setEditPanel(thisRow)
            e.stopPropagation(); // don't select this row after clicking
          };
          const onClickToogleActie = e => {
            setActiveAction(thisRow)
            e.stopPropagation(); // don't select this row after clicking
          }

          return (
            <Dropdown
              trigger={
                <IconButton>
                  <MoreVertIcon sx={{fontSize: '1.25rem' }}/>
                </IconButton>
              }
              menu={[
                <DropdownMenuItem onClick={onClickEdit}>Editar</DropdownMenuItem>,
                <DropdownMenuItem onClick={onClickToogleActie}>{params.row.active ? 'Desactivar' : 'Activar'}</DropdownMenuItem>,
              ]}
            />
          );
        },
      }
    ];

  const [options,setOptions]=React.useState({
    takeValue:10,
    skipValue:0,
    rowsPerPage:10,
  });
  
  const getCars = React.useCallback(async () => {
    const { takeValue,skipValue } = options
    const response = await axiosPrivate.post(GET_PAGINATED_CARS,
      JSON.stringify({takeValue,skipValue}),
      {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      }
    );
    console.log(response);
    if (response) {
      setData({ loading: false, data: response.data.data, totalRowCount: response.data.totalRowCount });
    }
  }, [axiosPrivate, options])



  const toogleActiveUser = async () => {
    await axiosPrivate.post(
      TOGLE_ACTIVE_CAR,
      JSON.stringify({ activeAction }),
      {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
      }
  ).then(() => {
      setToast({
          open: true,
          message: "Vehículo activada/desactivada con éxito",
          severity: "success",
      })
      getCars()
  }).catch(() => {
      setToast({
          open: true,
          message: "Error activando/desactivando Vehículo intentelo de nuevo más tarde.",
          severity: "error",
      })
      getCars()
  });
    setActiveAction(false);
    
  }


  React.useEffect(() => {
    getCars();
  }, [getCars]);

  if (data.loading) {
    return <LoadingSection />;
  }
  return (
    <LogedLayout>
      <Box m="20px">
        <Header 
          title="Vehículos" 
          subtitle="Listado de Vehículos"  
          buttonText={'Añadir Vehículo'} 
          buttonAction={() => setCreatePanel(true)}
        />
        {data && 
        <EnhancedTable
          data={data.data}
          options={options}
          setOptions={setOptions}
          header={USER_COLUMN}
          checkboxSelection
          totalRow={data.totalRowCount}
          onRowClick={() => setEditPanel(true)}
        />
        }
      </Box>
      <CarUpdate car={editPanel} setToast={setToast} refetch={getCars} />
      <CarCreate openPanel={createPanel} closePanel={setCreatePanel} setToast={setToast}  refetch={getCars}/>
 
      <EnhancedToast toast={toast} setToast={setToast}/>
      <EnhancedModal 
        closeAction={() => setActiveAction(false)} 
        acceptAction={() => toogleActiveUser()} 
        closeButton={'Cancelar'}
        acceptButton={'Aceptar'}
        title={'Gestión Vehículo'}
        body={'Está acción activara/desactivará el siguiente Vehículo, ¿Quiere continuar?'} 
        open={!!activeAction}
      />
     
    </LogedLayout>
  );




};

export default Car;
