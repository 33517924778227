import { 
    Box,
    TextField,
    FormControl,
    InputLabel,
    Select, 
    MenuItem
} from "@mui/material";
import { CAR_TYPES } from "../../common/constants";


const CarForm = ({
    carData,
    setCarData,
    errors={},
    users=[]
}) => (
    <Box m="20px">
          <form>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            >
              <TextField
                fullWidth
                type="text"
                label="Marca"
                onChange={event => setCarData({...carData, brand: event.target.value.trim()})}
                value={carData?.brand}
                name="brand"
                error={!!errors.brand}
                helperText={errors.brand}
                sx={{ gridColumn: "span 1" }}
              />
              <TextField
                fullWidth
                type="text"
                label="Modelo"
                onChange={event => setCarData({...carData, model: event.target.value.trim()})}
                value={carData?.model}
                name="model"
                error={!!errors.model}
                helperText={errors.model}
                sx={{ gridColumn: "span 2" }}
              />
               <TextField
                fullWidth
                type="text"
                label="Matricula"
                onChange={event => setCarData({...carData, license_plate: event.target.value.trim()})}
                value={carData?.license_plate}
                name="license_plate"
                error={!!errors.license_plate}
                helperText={errors.license_plate}
                sx={{ gridColumn: "span 1" }}
              />
              
         
               <TextField
                fullWidth
                type="text"
                label="Kilometros iniciales"
                onChange={event => setCarData({...carData, initial_kilometers: event.target.value})}
                value={carData?.initial_kilometers}
                name="initial_kilometers"
                error={!!errors.initial_kilometers}
                helperText={errors.initial_kilometers}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                type="text"
                label="Compañia"
                onChange={event => setCarData({...carData, company: event.target.value})}
                value={carData?.company}
                name="company"
                error={!!errors.company}
                helperText={errors.company}
                sx={{ gridColumn: "span 2" }}
              />

               <FormControl fullWidth  sx={{ gridColumn: 'span 2'}}>
                <InputLabel id="selectuusers">Usuario Responsable</InputLabel>
                <Select
                    label='Usuario Responsable'
                    helperText={errors.userId}
                    value={carData?.userId}
                    onChange={event => setCarData({...carData, userId: event.target.value})}
                    >
                    {users.length > 0 && users.map(user => (
                        <MenuItem key={user.id} value={user.id} >{`${user.name}`} {`${user.surnames}`}</MenuItem>
                        ))} 
                </Select>
               </FormControl >

               <FormControl fullWidth  sx={{ gridColumn: 'span 2'}}>
                <InputLabel id="demo-simple-select-standard-label">Tipo de Vehículo</InputLabel>
                <Select
                    label='Tipo de Vehículo'
                    error={!!errors.type}
                    value={carData?.type}
                    onChange={event => setCarData({...carData, type: event.target.value})}
                    >
                    {CAR_TYPES.map(carType => (
                        <MenuItem key={carType.value} value={carType.value} >{`${carType.label}`}</MenuItem>
                    ))} 
                </Select>
               </FormControl >

            </Box>
          </form>
    </Box>
);

export default CarForm;