import React from "react";
import Topbar from "./Topbar";

const NotLoggedLayout = ({ children }) => {
  return (
    <div className="app">
      <main className="content" style={{ height: "100%", width: "100%" }}>
        <Topbar hideSearch={true} hideUser={true} hideLogo={false} />
        <div
          style={{
            height: "calc(100vh - 4rem)",
            width: "100%",
          }}
        >
        {children}
        </div>
      </main>
    </div>
  );
};

export default NotLoggedLayout;
