import { useRef, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import useAuth from "../../hooks/useAuth.js";
import axios from "../../api/axios.js";
import { LOGIN_URL } from "../../common/constants.js";
import NotLoggedLayout from "../../components/shared/NotLoggedLayout.js";
import {
  Avatar,
  Box,
  Button,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import loginImg from '../../assets/login.svg'
import { useTheme } from "@emotion/react";
import { tokens } from "../../theme/theme.js";

const Login = () => {
  const { setAuth } = useAuth();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";

  const emailRef = useRef();
  const errRef = useRef();

  const [email, setEmail] = useState("");
  const [pwd, setPwd] = useState("");
  const [errMsg, setErrMsg] = useState("");

  useEffect(() => {
    emailRef.current.focus();
  }, []);

  useEffect(() => {
    setErrMsg("");
  }, [email, pwd]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        LOGIN_URL,
        JSON.stringify({ email, password: pwd }),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );
      const data = JSON.parse(JSON.stringify(response?.data?.data));
      const accessToken = data?.accessToken;
      const roles = data?.user?.role;
      const user = data?.user;
      setAuth({ user, roles, accessToken });
      setEmail("");
      setPwd("");
      navigate(from, { replace: true });
    } catch (err) {
      console.log("errr >>>>", err);
      if (!err?.response) {
        setErrMsg("No Server Response");
      } else if (err.response?.status === 400) {
        setErrMsg("Missing Username or Password");
      } else if (err.response?.status === 401) {
        setErrMsg("Unauthorized");
      } else {
        setErrMsg("Login Failed");
      }
      errRef.current.focus();
    }
  };
  return (
    <NotLoggedLayout>
      <Grid container component="main" sx={{ height: "100%" }}>
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: `url(${loginImg})`,
            backgroundRepeat: "no-repeat",
            backgroundColor: colors.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <Grid item xs={12} sm={8} md={5}  elevation={6} square='true'>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: !errMsg ? "secondary.main": 'error.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            <div
              ref={errRef}
              className={errMsg ? "errmsg" : "offscreen"}
              aria-live="assertive"
            >
            <Typography component="h1" variant="h4" color={'error.main'}>
            {errMsg === 'Unauthorized' ?
              "Estas credenciales no coinciden con nuestros registros."
              :
              errMsg
              }
          </Typography>
           
            </div>
            <Typography component="h1" variant="h3" >
              Inicio de sesión
            </Typography>
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{ mt: 1 }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Correo Electrónico"
                ref={emailRef}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                name="email"
                autoComplete="email"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Contraseña"
                type="password"
                id="password"
                onChange={(e) => setPwd(e.target.value)}
                value={pwd}
                autoComplete="current-password"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2, fontSize: '1.25rem' }}
              >
                Iniciar Sesión
              </Button>
              <Grid container>
                <Grid item xs>
                  <a href="/forgot-passwd" style={{ fontSize: '1.05rem', color: '#3880FF'}}>
                    ¿Olvidó la contraseña?
                  </a>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </NotLoggedLayout>
  );
};

export default Login;
