import React from 'react';
import { GET_OTP_BY_ID, INITIAL_OTP_VALUE, UPDATE_OTP, INITIAL_OTP_VALUE_ERROR } from '../../common/constants';
import Panel from '../../components/shared/Panel';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import OtpForm from './OtpForm';
import { getDatFromColumn } from "../../common/utils";
import { CheckOtpRequiredFields } from '../../common/utils';


const OtpUpdate = ({ otp, setToast, refetch }) => {
    const [open, setOpen] = React.useState(!!otp);
    const axiosPrivate = useAxiosPrivate();
    const [errors, setErrors] = React.useState(INITIAL_OTP_VALUE_ERROR);
    const [otpMapper, setOtpMapper] = React.useState(INITIAL_OTP_VALUE);


    const getOtp = async () => {
        const response = await axiosPrivate.get(GET_OTP_BY_ID.replace(':id', otp.id));
        if (response.data.data) {
            setOtpMapper({...INITIAL_OTP_VALUE, ...response.data.data});
            setOpen(true)
        }
    }

    const updateOtp = async () => {
        const checkErrors = CheckOtpRequiredFields(otpMapper);
        if (checkErrors.errorCounter === 0) {

            await axiosPrivate.put(
                UPDATE_OTP,
                JSON.stringify({ otpMapper }),
                {
                    headers: { "Content-Type": "application/json" },
                    withCredentials: true,
                }
            ).then(() => {
                refetch()
                setToast({
                    open: true,
                    message: "OTP Modificado con éxito",
                    severity: "success",
                })
            }).catch(() => {
                setToast({
                    open: true,
                    message: "Error modificando OTP intentelo de nuevo más tarde.",
                    severity: "error",
                })
            });
            setOpen(false)
            setErrors(INITIAL_OTP_VALUE_ERROR)


        } else {
            setErrors(checkErrors.otpErrors)
        }
    }


    React.useEffect(() => {
        if (otp.id && !open) {
            getOtp();
        }
    }, [otp]);

    React.useEffect(() => {
    }, [errors]);

    return (
        <Panel
            title={"Editar OTP"}
            open={open}
            bodyStyle={{ overflow: "hidden" }}
            bodyText={<OtpForm otpData={otpMapper} setOtpData={setOtpMapper} errors={errors} update={true}/>}
            buttonAccept={"aceptar"}
            acceptAction={() => updateOtp()}
            requestClose={() => { setErrors(INITIAL_OTP_VALUE_ERROR); setOtpMapper(INITIAL_OTP_VALUE); setOpen(false); } }
        />
    )
};

export default OtpUpdate;