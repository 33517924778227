import React from 'react';
import { GET_USER_BY_ID, INITIAL_USER_VALUE, UPDATE_USER, INITIAL_USER_VALUE_ERROR } from '../../common/constants';
import { CheckUserRequiredFields } from '../../common/utils';
import Panel from '../../components/shared/Panel';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import UserForm from './userForm';


const UserUpdate = ({user, setToast, refetch}) => {
    const [open, setOpen] = React.useState(!!user);
    const axiosPrivate = useAxiosPrivate();
    const [errors, setErrors] = React.useState(INITIAL_USER_VALUE_ERROR);
    const [userMapper, setUserMapper] = React.useState(INITIAL_USER_VALUE);

    const builderUserMapper = (user) => {
        const handleTmpUserMapper = {
            id: user.id,
            name: user.name,
            surnames: user.surnames,
            workState: user.workState,
            contractNumber: user.contractNumber,
            email: user.email,
            concepts: {
              ACS_U_PL_DESPLAZAM: !!user.userConcepts.find(data => data.concept.description === 'ACS_U_PL_DESPLAZAM' ),
              ACS_U_MDIA_DIETA: !!user.userConcepts.find(data => data.concept.description === 'ACS_U_MDIA_DIETA' ),
              ACS_U_PLUS_AY_MANU: !!user.userConcepts.find(data => data.concept.description === 'ACS_U_PLUS_AY_MANU' ),
            }
          };
        setUserMapper(handleTmpUserMapper);


    }

    const getUser = async () => {
        const response = await axiosPrivate.get(GET_USER_BY_ID.replace(':id', user.id));
        if(response.data.userData) {
            builderUserMapper(response.data.userData);
            setOpen(true)
        } 
    }

    const updateUser = async () => {
        const checkErrors = CheckUserRequiredFields(userMapper);
        if(checkErrors.errorCounter === 0){
            await axiosPrivate.put(
                UPDATE_USER,
                JSON.stringify({ userMapper }),
                {
                    headers: { "Content-Type": "application/json" },
                    withCredentials: true,
                }
            ).then(() => {
                refetch();
                setToast({
                    open: true,
                    message: "Usuario modificado con éxito",
                    severity: "success",
                })   
            })
            .catch(() => {
                setToast({
                    open: true, 
                    message: "Error modificado usuario, intentelo de nuevo.",
                    severity: "error",
                });        
            });
        
            setOpen(false);
            setErrors(INITIAL_USER_VALUE_ERROR); 
        }else{
            setErrors(checkErrors.userErrors)
        }
    }

    React.useEffect(() => {
        if(user.id && !open){
            getUser();
        }
    },[user]);


    return (
        <Panel
        title={"EDITAR USUARIO"}
        open={open}
        bodyStyle={{ overflow: "hidden" }}
        bodyText={<UserForm userData={userMapper} setUserData={setUserMapper} errors={errors}/>}
        buttonAccept={"aceptar"}
        acceptAction={() => updateUser()}
        requestClose={() => { setErrors(INITIAL_USER_VALUE_ERROR); setUserMapper(INITIAL_USER_VALUE); setOpen(false); } }
        />
    )
};

export default UserUpdate;