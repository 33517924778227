import React from 'react';
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import moment from 'moment';


const EnhancedTimePicker = ({
    label,
    error,
    sx,
    timeSteps,
    maxTime,
    ampm,
    setSigningData,
    signingData,
  }) => {

  return (
      <TimePicker
        label={label}
        timeSteps={timeSteps}
        maxTime={maxTime}
        ampm={ampm}
        error={error}
        value={moment().set({'hour': ('0' +Math.floor(signingData?.hours) % 24).slice(-2), 'minute': ((signingData?.hours % 1)*60 + '0').slice(0, 2)}) }
        sx={sx}
        slotProps={{ textField: { fullWidth: true } }}
        onChange={event => setSigningData({...signingData, hours: parseFloat((event._d.getHours()+(event._d.getMinutes()/60))+'')})}
        />
  );
}

export default EnhancedTimePicker;
