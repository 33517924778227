import { INITIAL_OTP_VALUE } from "./constants";

export const getDatFromColumn = params =>{
    const api = params.api;
    const thisRow = {};

    api
      .getAllColumns()
      .filter((c) => c.field !== "__check__" && !!c)
      .forEach(
        (c) => (thisRow[c.field] = params.row)
      );
    return thisRow;
}

export const checkSigningForm = signingData => {
  const signingErrors = {};
  const errorCounter = []

  if(!signingData.otpId  || signingData.otpId < 0 ){
    signingErrors.otpId = 'El parte de trabajo es un campo obligatorio'
    errorCounter.push('otpId')
  }
  if(!signingData.hours || signingData.hours === ''){
    signingErrors.hours = 'Horas es un campo obligatorio'
    errorCounter.push('hours')

  }else if(signingData.hours > 8 || signingData.hours < 0 ){
    signingErrors.hours = 'Comprueba que las horas no sean superiores a 8 ni inferiores a 0'
    errorCounter.push('hours')
  }
  return {
    errorCounter: errorCounter.length,
    signingErrors
  };

}

export const CheckUserRequiredFields = userData => {
  const userErrors = {};
  const errorCounter = []
  if (!userData.id || userData.id === '') {
      userErrors.id = 'Identificador es un campo obligatorio'
      errorCounter.push('id')
  }
  
  if (!userData.name || userData.name.trim() === '') {
    userErrors.name = 'Nombre es un campo obligatorio';
    errorCounter.push('name')
  }

  if (!userData.surnames || userData.surnames.trim() === '') {
    userErrors.surnames = 'Apellidos es un campo obligatorio'
    errorCounter.push('surnames');
  }

  if (!userData.workState || userData.workState.trim() === '') {
      userErrors.workState = 'Centro de trabajo es un campo obligatorio'
      errorCounter.push('workState');
  }

  if (!userData.contractNumber || userData.contractNumber === '') {
    userErrors.contractNumber = 'Contrato es un campo obligatorio'
    errorCounter.push('contractNumber');
  }

  if (!userData.email || userData.email.trim() === '') {
    userErrors.email = 'Correo electrónico  es un campo obligatorio'
    errorCounter.push('email');
  }
  
  return {
    errorCounter: errorCounter.length,
    userErrors
  };

};

export const CheckOtpRequiredFields = otpData => {
  const otpErrors = {};
  const errorCounter = []
  if (!otpData.id || otpData.id === "") {
      otpErrors.id = 'Identificador es un campo obligatorio'
      errorCounter.push('id')
  }

  if(otpData.id.toString().match(/[`~$'<>]/gi)){
    otpErrors.id = 'No se admiten caracteres especiales';
    errorCounter.push('id')
  }
  
  if (!otpData.name || otpData.name.trim() === '') {
    otpErrors.name = 'Nombre es un campo obligatorio';
    errorCounter.push('name')
  }
  if(otpData.name.match(/[`~$'<>]/gi)){
    otpErrors.name = 'No se admiten caracteres especiales';
    errorCounter.push('name')
  }

  if (!otpData.description || otpData.description.trim() === '') {
    otpErrors.description = 'Descripción es un campo obligatorio'
    errorCounter.push('description');
  }
  if(otpData.description.match(/[`~$'<>]/gi)){
    otpErrors.description = 'No se admiten caracteres especiales';
    errorCounter.push('description')
  }

  if (otpData.hours=== "") {
      otpErrors.hours = 'Horas es un campo obligatorio'
      errorCounter.push('hours');
  }

  if (otpData.estimationHours === "") {
    otpErrors.estimationHours = 'Horas estimadas es un campo obligatorio'
    errorCounter.push('estimationHours');
  }



  
  return {
    errorCounter: errorCounter.length,
    otpErrors
  };

};


export const  zfill = (number, width) => {
  var numberOutput = Math.abs(number); /* Valor absoluto del número */
  var length = number.toString().length; /* Largo del número */ 
  var zero = "0"; /* String de cero */  
  
  if (width <= length) {
      if (number < 0) {
           return ("-" + numberOutput.toString()); 
      } else {
           return numberOutput.toString(); 
      }
  } else {
      if (number < 0) {
          return ("-" + (zero.repeat(width - length)) + numberOutput.toString()); 
      } else {
          return ((zero.repeat(width - length)) + numberOutput.toString()); 
      }
  }
}


export const CheckCarRequiredFields = carData => {
  const userErrors = {};
  const errorCounter = []

  if (!carData.type || carData.type.trim() === '') {
    userErrors.type = 'El tipo de Vehículo es un campo obligatorio';
    errorCounter.push('type')
  }

  if (!carData.license_plate || carData.license_plate.trim() === '') {
    userErrors.license_plate = 'Matricula es un campo obligatorio'
    errorCounter.push('license_plate');
  }

  if (!carData.brand || carData.brand.trim() === '') {
      userErrors.brand = 'La marca es un campo obligatorio'
      errorCounter.push('brand');
  }

  if (!carData.model || carData.model === '') {
    userErrors.model = 'Modelo es un campo obligatorio'
    errorCounter.push('model');
  }

  if (!carData.company || carData.company === '') {
    userErrors.company = 'Compañia es un campo obligatorio'
    errorCounter.push('company');
  }

  if (!parseFloat(carData.initial_kilometers) || parseFloat(carData.initial_kilometers).toFixed(2) < 0.00) {
    userErrors.initial_kilometers = 'KM iniciales es un campo obligatorio'
    errorCounter.push('initial_kilometers');
  }
  
  return {
    errorCounter: errorCounter.length,
    userErrors
  };

};



export const CheckRegisterCarRequiredFields = carData => {
  const userErrors = {};
  const errorCounter = []

  if (!carData.date || carData.date.trim() === '') {
    userErrors.date = 'La fecha es un campo obligatorio';
    errorCounter.push('date')
  }

  if (!carData.total_kilometers || carData.total_kilometers.trim() === '') {
    userErrors.total_kilometers = 'Kilometros es un campo obligatorio'
    errorCounter.push('total_kilometers');
  }
  
  return {
    errorCounter: errorCounter.length,
    userErrors
  };

};
