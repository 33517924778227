import { Box, TextField } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { INITIAL_OTP_VALUE } from "../../common/constants";

const OtpForm = ({otpData = INITIAL_OTP_VALUE, setOtpData, errors={}, update=false}) => {
  const isNonMobile = useMediaQuery("(min-width:600px)");


  return (
    <Box m="20px">
          <form>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Identificador"
                onChange={event => setOtpData({...otpData, id: event.target.value.trim()})}
                value={otpData.id}
                name="id"
                error={!!errors.id}
                disabled={update}
                helperText={errors.id}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Nombre"
                onChange={event => setOtpData({...otpData, name: event.target.value})}
                value={otpData.name}
                name="name"
                error={!!errors.name}
                helperText={errors.name}
                sx={{ gridColumn: "span 2" }}
              />

               <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Descripción"
                onChange={event => setOtpData({...otpData, description: event.target.value})}
                value={otpData.description}
                name="description"
                error={!!errors.description}
                helperText={errors.description}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Horas"
                onChange={event => setOtpData({...otpData, hours: event.target.value.trim()})}
                value={otpData.hours}
                name="hours"
                error={!!errors.hours}
                helperText={errors.hours}
                sx={{ gridColumn: "span 2" }}
              />
                <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Estimación de Horas"
                onChange={event => setOtpData({...otpData, estimationHours: event.target.value.trim()})}
                value={otpData.estimationHours}
                name="estimationHours"
                error={!!errors.estimationHours}
                helperText={errors.estimationHours}
                sx={{ gridColumn: "span 4" }}
              />

            </Box>
          </form>
    </Box>
  );
};

export default OtpForm;