import React from 'react';
import { Box , IconButton} from "@mui/material";
import Header from "../../components/shared/Header";
import LogedLayout from "../../components/shared/LogedLayout.js";
import { OTPS_URL } from "../../common/constants.js";
import useAxiosPrivate from "../../hooks/useAxiosPrivate.js";
import LoadingSection from '../../components/shared/LoadingSection.js';
import EnhancedTable from '../../components/shared/EnhancesTable.js';
import { getDatFromColumn } from "../../common/utils";
import { Dropdown, DropdownMenuItem } from "../../components/shared/Dropdown";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import OtpUpdate from './OtpUpdate.js';
import OtpCreate from './OtpCreate.js';
import EnhancedToast from "../../components/shared/enhancesToast";
import EnhancedModal from "../../components/shared/EnhancedModal";
import { TOOGLE_ACTIVE_OTP } from '../../common/constants';

const Otps = () => {
  const [otps, setOtps] = React.useState({ loading: true });
  const axiosPrivate = useAxiosPrivate();
  const [editPanel, setEditPanel] = React.useState(false);
  const [createPanel, setCreatePanel] = React.useState(false);
  const [activeAction, setActiveAction] = React.useState(false);
  const [toast, setToast] = React.useState({
    open: false,
    message: "",
    severity: "success",
  })

  const [options, setOptions] = React.useState({
    takeValue: 10,
    skipValue: 0,
    rowsPerPage: 10,
  })

  const toogleActiveOtp = async () => {
    await axiosPrivate.post(
      TOOGLE_ACTIVE_OTP,
      JSON.stringify({ activeAction }),
      {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
      }
  ).then(() => {
      setToast({
          open: true,
          message: "OTP activada/desactivada con éxito",
          severity: "success",
      })
      getOtps()
  }).catch(() => {
      setToast({
          open: true,
          message: "Error activando/desactivando OTP intentelo de nuevo más tarde.",
          severity: "error",
      })
      getOtps()
  });
    setActiveAction(false);
  }

  const getOtps = React.useCallback(async () => {
    const { takeValue, skipValue } = options
    const response = await axiosPrivate.post(OTPS_URL,
      JSON.stringify({ takeValue, skipValue }),
      {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      }
    );
    if (response) {
      setOtps({ loading: false, data: response.data.data, totalRowCount: response.data.totalRowCount });

    }
  }, [axiosPrivate, options]);
  const OTP_COLUMNS = [
    { field: "id", headerName: "ID", flex: 0.5 },
    {
      field: "name",
      headerName: "Nombre",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "hours",
      headerName: "Horas",
      type: "number",
      headerAlign: "left",
      align: "left",
    },
    {
      field: "estimationHours",
      headerName: "Horas estimadas",
      flex: 1,
    },
    {
      field: "description",
      headerName: "Descripción",
      flex: 1,
    },
    {
      field: "active",
      headerName: "Estado",
      flex: 1,
      renderCell: (params) => {
        const thisRow = getDatFromColumn(params);
        return (
          thisRow.active.active ? 'Activado' : 'Desactivado'
        )
      }
    },
    {
      field: "actions",
      headerName: "Acciones",
      flex: 1,
      renderCell: (params) => {
        const thisRow = getDatFromColumn(params).id;
        const onClickEdit = e => {
          setEditPanel(thisRow)
          e.stopPropagation(); // don't select this row after clicking
        };
        const onClickToogleActie = e => {
          setActiveAction(thisRow)
          e.stopPropagation(); // don't select this row after clicking
        }

        return (
          <Dropdown
            trigger={
              <IconButton>
                <MoreVertIcon sx={{ fontSize: '1rem' }} />
              </IconButton>
            }
            menu={[
              <DropdownMenuItem onClick={onClickEdit}>Editar</DropdownMenuItem>,
              <DropdownMenuItem onClick={onClickToogleActie}>{params.row.active ? 'Desactivar' : 'Activar'}</DropdownMenuItem>,
            ]}
          />
        );
      },
    }
  ];

  React.useEffect(() => {
    getOtps()
  }, [getOtps]);


  if (otps.loading) {
    return <LoadingSection />
  }

  return (
    <LogedLayout>
      <Box m="20px">
        <Header
          title="Listado de otps"
          subtitle="Listado de todas las otps"
          buttonText={'Añadir OT'}
          buttonAction={() => setCreatePanel(true)}
        />
  
          {otps?.data && <>
            <EnhancedTable
              data={otps.data}
              header={OTP_COLUMNS}
              options={options}
              setOptions={setOptions}
              totalRow={otps.totalRowCount}
              checkboxSelection
              onRowClick={() => setEditPanel(true)}
            />
          </>}
        <OtpUpdate otp={editPanel} setToast={setToast} refetch={getOtps} />
        <OtpCreate openPanel={createPanel} closePanel={setCreatePanel} setToast={setToast} refetch={getOtps}/>
        <EnhancedToast toast={toast} setToast={setToast} />
        <EnhancedModal
          closeAction={() => setActiveAction(false)}
          acceptAction={() => toogleActiveOtp()}
          closeButton={'Cancelar'}
          acceptButton={'Aceptar'}
          title={'Gestión OTP'}
          body={'Está acción activara/desactivará el siguiente otp ¿Quiere continuar?'}
          open={activeAction}
        />
      </Box>
    </LogedLayout>
  )
};

export default Otps;