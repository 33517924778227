import React from 'react';
import { GET_ALL_USERS, GET_SIGNING_BY_ID, INITIAL_SIGNING_VALUE, INITIAL_SIGNING_VALUE_ERROR } from '../../common/constants';
import { checkSigningForm } from '../../common/utils';
import Panel from '../../components/shared/Panel';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import SigningForm from './estimatedSigningForm';
import moment from "moment";


const EstimatedSigningCreate = ({open, setToast, refetch, setOpen, otps}) => {
    
    const axiosPrivate = useAxiosPrivate();
    const [errors, setErrors] = React.useState(INITIAL_SIGNING_VALUE_ERROR);
    const [signingMapper, setSigningMapper] = React.useState(INITIAL_SIGNING_VALUE);
    const [users, setUsers] = React.useState({});

    const builderUserMapper = () => {
        const handleTmpUserMapper = {
            id: "",
            date: moment().format('YYYY-MM-DD'),
            user: "",
            hours: "",
            otpId: "",
            description: "",
            concepts: {
              ACS_U_PL_DESPLAZAM: false,
              ACS_U_MDIA_DIETA: false,
              ACS_U_PLUS_AY_MANU: false,
              VACACIONES: false,
              ACS_HORAS_ENF_PT: false,
            }
          };
        setSigningMapper(handleTmpUserMapper);
    }

    const getUsers = React.useCallback( async () => {
        const response = await axiosPrivate.get(GET_ALL_USERS);
        if(response.status === 201) {
            setUsers(response.data.data)
        }
      });
  


    const createSigning = async () => {
        const checkErrors = checkSigningForm(signingMapper);
        if(checkErrors.errorCounter === 0){
            const {id, userId, otpId, date, hours, concepts, description } = signingMapper;
            await axiosPrivate.post(
                '/fakeSigning',
                JSON.stringify({ id, userId, otpId, date, hours, concepts, description }),
                {
                    headers: { "Content-Type": "application/json" },
                    withCredentials: true,
                }
                ).then(() => {
                    refetch();
                setToast({
                    open: true,
                    message: "Imputación estimada creada con éxito",
                    severity: "success",
                })   
            })
            .catch(() => {
                setToast({
                    open: true,
                    message: "Error, recuerde imputar como máximo 8h",
                    severity: "error",
                })          
            });
            setOpen(false);
            setErrors(INITIAL_SIGNING_VALUE_ERROR)
        }else{
            setErrors(checkErrors.signingErrors)
        }
    }

    React.useEffect(() => {
        if(open){
            getUsers();
            builderUserMapper();
        }
    },[open]);

    return (
        <Panel
        title={"CREAR IMPUTACIÓN"}
        open={open}
        bodyStyle={{ overflow: "hidden" }}
        bodyText={<SigningForm signingData={signingMapper} setSigningData={setSigningMapper} errors={errors} otps={otps} activeAllFields={true} users={users} />}
        buttonAccept={"aceptar"}
        acceptAction={() => createSigning()}
        requestClose={() => { setErrors(INITIAL_SIGNING_VALUE_ERROR); setSigningMapper(INITIAL_SIGNING_VALUE); setOpen(false);} }
        />
    )
}

export default EstimatedSigningCreate;
