import axios from "../api/axios";
import useAuth from "./useAuth";

const useRefreshToken = () => {
  const { setAuth } = useAuth();
  
  const refresh = async () => {
    const response = await axios
      .post("/refresh", {
        credentials: "include",
      })
      .catch(function (error) {
        console.log(error.message);
      });
    
    if (response) {
      const data = JSON.parse(JSON.stringify(response?.data?.data));
      const accessToken = data?.accessToken;
      const roles = data?.user?.role;
      const user = data?.user;
      setAuth({ user, roles, accessToken });
      return response.data.data.accessToken;
    }
    return { status: "error" };
  };
  return refresh;
};

export default useRefreshToken;


