

const useLocalStorage = () => {
	const getlastQueryStorage = (name = '') => {
		const lastQueryStorage = JSON.parse(localStorage.getItem(`${name}`)) || {};
		return lastQueryStorage;
	};

	const setLastQueryStorage = (props, name = '') => {
		localStorage.setItem(`${name}`, JSON.stringify(props));
	};

	return {
		getlastQueryStorage,
		setLastQueryStorage
	};
};

export default useLocalStorage;