import React, { useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "../../api/axios.js";
import { CHANGE_PASSWD, VALDIATE_CHANGE_PASSWD_TOKEN } from "../../common/constants.js";
import NotLoggedLayout from "../../components/shared/NotLoggedLayout.js";
import {
  Avatar,
  Box,
  Button,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

import changePasswd from '../../assets/changePasswd.svg';
import { tokens } from "../../theme/theme.js";
import { useTheme } from "@emotion/react";
import {  IconButton, InputAdornment } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const ChangePasswd = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const errRef = useRef();
  const pathname = useLocation().pathname;
  const [showPassword, setShowPassword] = useState(false);
  const [showRepitPassword, setShowRepitPassword] = useState(false);

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleShowRepitPassword = () => {
    setShowRepitPassword(!showRepitPassword);
  };

  const [data, setData] = useState({
    passwd: '',
    repeatPasswd: ''
  })
  const [message, setMessage] = useState({
    type: "",
    text: "",
  });
  const [token, setToken] = useState('');
  const navigate = useNavigate();

  // React.useEffect(async () => {
  //   const handleToken = pathname.split('/changePasswd/')[1]
  //   const response =  await axios.post(
  //     VALDIATE_CHANGE_PASSWD_TOKEN,
  //     JSON.stringify({ jwt: handleToken }),
  //     {
  //       headers: { "Content-Type": "application/json" },
  //       withCredentials: true,
  //     }
  //   );    
  //   if(response.data && response.data.status === 'success'){
  //     setToken(handleToken);
  //   }else {
  //     setToken(null);
  //     navigate('/', { replace: true });
  //   }
  // }, [pathname]);

  // React.useEffect(() => {
  //   if(message.type === 'success'){
  //     setTimeout(() => {
  //       navigate('/', { replace: true });
  //     }, 1000);
  //   }
  // }, [message]);

  React.useEffect(() => {
    const handleToken = pathname.split('/changePasswd/')[1];
    const validateToken = async () => {
      const response = await axios.post(
        VALDIATE_CHANGE_PASSWD_TOKEN,
        JSON.stringify({ jwt: handleToken }),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );    
      if (response.data && response.data.status === 'success') {
        setToken(handleToken);
      } else {
        setToken(null);
        navigate('/', { replace: true });
      }
    };
    validateToken();
  }, [pathname,navigate]);
  
  React.useEffect(() => {
    if (message.type === 'success') {
      setTimeout(() => {
        navigate('/', { replace: true });
      }, 1000);
    }
  }, [message,navigate]);



  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        CHANGE_PASSWD,
        JSON.stringify({ ...data, token }),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );
      const builderMessage = {
        type: "success",
        text: "Contraseña cambiada correctamante",
      }
      if(response.data && response.data.status === 'Same passwd'){
        builderMessage.type = 'error';
        builderMessage.text = 'Inserte una contraseña, diferente a la anterior';
      } else if (response.data && response.data.status === 'token invalid'){
        builderMessage.type = 'error';
        builderMessage.text = 'El acceso mediante token, ha caducado, intentelo de nuevo repitiendo el proceso.';
      }else  if(!response.data || (response.data && response.data.status !== 'passwd changes')){
        builderMessage.type = 'error';
        builderMessage.text = 'Fallo del servidor, intentelo de nuevo o más tarde';
      }
      setMessage(builderMessage);
    } catch (err) {
      setMessage({
        type: "error",
        text: "Fallo del servidor, intentelo de nuevo o más tarde",
      });
      errRef.current.focus();
    }
    setData({
      passwd: '',
      repeatPasswd: ''
    })
  };


  return (
    <NotLoggedLayout>
      <Grid container component="main" sx={{ height: "100%" }}>
      <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: `url(${changePasswd})`,
            backgroundRepeat: "no-repeat",
            backgroundColor: colors.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <Grid item xs={12} sm={8} md={5}  elevation={6} square='true'>  
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
           <Avatar sx={{ m: 1, bgcolor: message.type !== 'error' ? "secondary.main": 'error.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            <div
              ref={errRef}
              className={message.type === 'error'  ? "errmsg" : "offscreen"}
              aria-live="assertive"
            >
            <Typography component="h1" variant="h4" color={message.type === 'error' ? 'error.main' : 'secondary.main'} style={{ textAlign: 'center', marginBottom: '0.5rems'}}>
              {message.text}
          </Typography>
          </div>
            {message.type !== 'success' && token !== null  && 
            <>
            <Typography component="h1" variant="h3">
              Cambiar Contraseña
            </Typography>
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{ mt: 1, width: '90%' }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="passwd"
                label="Contraseña"
                value={data.passwd}
                onChange={(e) => setData({ ...data, passwd: e.target.value})}
                name="passwd"
                autoFocus
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleShowPassword}>
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              

               <TextField
                margin="normal"
                required
                fullWidth
                id="repeatPasswd"
                label="Repetir Contraseña"
                value={data.repeatPasswd}
                onChange={(e) => setData({ ...data, repeatPasswd: e.target.value})}
                name="repeatPasswd"
                type={showRepitPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleShowRepitPassword}>
                        {showRepitPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                autoFocus
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2, fontSize: '1.25rem' }}
              >
                Cambiar Contraseña
              </Button>
              <Grid container>
                <Grid item xs>
                <a href="/signing" style={{ fontSize: '1.05rem', color: '#3880FF'}}>
                    Iniciar sesión
                  </a>
                </Grid>
              </Grid>
            </Box>
            </>
          }
          
          </Box>
        </Grid>
      </Grid>
    </NotLoggedLayout>
  );
};

export default ChangePasswd;
