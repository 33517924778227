import React, { useContext, useState } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, Divider, IconButton, Typography, useTheme } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { ColorModeContext, tokens } from "../../theme/theme.js";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import LogoutIcon from '@mui/icons-material/Logout';
import axios from "../../api/axios.js";
import WorkIcon from '@mui/icons-material/Work';
import { LOGOUT_URL } from "../../common/constants.js";
import useAuth from "../../hooks/useAuth.js";
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import TireRepairIcon from '@mui/icons-material/TireRepair';
import { WorkHistory } from "@mui/icons-material";


export const Item = ({ title, to, icon, selected, setSelected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <MenuItem
      active={selected === to}
      style={{
        color: colors.grey[100],
      }}
      onClick={() => { setSelected(title); }}
      icon={icon}
    >
      <Typography>{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );
};
const Sidebar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("Dashboard");
  const { auth, setAuth } = useAuth();
  const colorMode = useContext(ColorModeContext);

  const location = useLocation();

  React.useEffect(() => {
    setSelected(location.pathname)
  }, [location]);

  const loogut = async () =>{
    await axios.post(
      LOGOUT_URL,
      {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      }
    );
    setAuth({})
  }
  return (
    <Box
      sx={{
        "& .pro-sidebar-inner": {
          background: `${colors.primary[400]} !important`,
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "5px 35px 5px 20px !important",
        },
        "& .pro-inner-item:hover": {
          color: "#868dfb !important",
        },
        "& .pro-menu-item.active": {
          color: "#6870fa !important",
        },
      }}
    >
      <ProSidebar collapsed={isCollapsed}>
        <Menu iconShape="square">
          {/* LOGO AND MENU ICON */}
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
            style={{
              margin: "10px 0 20px 0",
              color: colors.grey[100],
            }}
          >
            {!isCollapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml="15px"
              >
                <Typography variant="h3" color={colors.grey[100]}>
                {auth.user.role.name  }  
                </Typography>
                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                  <MenuOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </MenuItem>

          {!isCollapsed && (
            <Box mb="25px">
              <Box textAlign="center">
                <Typography
                  variant="h2"
                  color={colors.grey[100]}
                  fontWeight="bold"
                  sx={{ m: "10px 0 0 0" }}
                >
                  {auth.user.name}
                </Typography>
                <Typography variant="h5" color={colors.greenAccent[500]}>
                {auth.user.surnames}
                </Typography>
              </Box>
            </Box>
          )}

          <Box paddingLeft={isCollapsed ? undefined : "10%"}>
          {!isCollapsed && 
            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              Acciones principales  
            </Typography>
          }
            <Item
              title="Dashboard"
              to="/"
              icon={<HomeOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
             <Item
              title="Usuarios"
              to="/users"
              icon={<PeopleOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            <Item
              title="OTS"
              to="/otps"
              icon={<ReceiptOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
               <Item
              title="Vehículos"
              to="/cars"
              icon={<DirectionsCarIcon />}
              selected={selected}
              setSelected={setSelected}
            />
              <Item
              title="Imputaciones"
              to="/signing"
              icon={<WorkIcon />}
              selected={selected}
              setSelected={setSelected}
            />
             <Item
              title="Estimación Imputaciones"
              to="/estimated/signing"
              icon={<WorkHistory />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Registro Vehículos"
              to="/cars/register"
              icon={<TireRepairIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Documentos"
              to="/documentation"
              icon={<DownloadForOfflineIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            {!isCollapsed && 
              <Typography
                variant="h6"
                color={colors.grey[300]}
                sx={{ m: "15px 0 5px 20px" }}
              >
                Personal
              </Typography>
            }
            {isCollapsed && 
             <Divider sx={{ m: "5px 15px 5px 15px" }}/>
            }
              
            <div onClick={() => { colorMode.toggleColorMode();}}>
  
            <Item
              title="Tema"
              icon={    theme.palette.mode === "dark" ? (
                <DarkModeOutlinedIcon />
              ) : (
                <LightModeOutlinedIcon />
              )}
              selected={selected}
              setSelected={setSelected}
            />
                </div>
                <div onClick={() => loogut()}>
              <Item
                title="Cerrar sesión"
                icon={<LogoutIcon />}
                selected={selected}
                setSelected={setSelected}
              />
            </div>
            
          </Box>
        </Menu>
      </ProSidebar>
    </Box>
  );
};

export default Sidebar;
