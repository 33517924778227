import React from "react";
import useAuth from "../../hooks/useAuth";
import Sidebar from "./Sidebar";
import Topbar from "./Topbar";

const LogedLayout = ({ children }) => {
  const [isSidebar, setIsSidebar] = React.useState(true);
  const { auth } = useAuth();

  return (
    <div className="app">
      <Sidebar isSidebar={isSidebar} auth={auth} />
      <main className="content" style={{ overflowY: 'scroll'}}>
        <Topbar setIsSidebar={setIsSidebar} />
        {children}
      </main>
    </div>
  );
};


export default LogedLayout;
