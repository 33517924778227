import { Box, FormControlLabel, TextField, Checkbox } from "@mui/material";


const UserForm = ({userData , setUserData, errors={}}) => (
    <Box m="20px">
          <form>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            >
              <TextField
                fullWidth
                type="text"
                label="Identificador"
                onChange={event => setUserData({...userData, id: event.target.value.trim()})}
                value={userData.id}
                name="id"
                error={!!errors.id}
                helperText={errors.id}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                type="text"
                label="Nº de conrtato"
                onChange={event => setUserData({...userData, contractNumber: event.target.value.trim()})}
                value={userData.contractNumber}
                name="contractNumber"
                error={!!errors.contractNumber}
                helperText={errors.contractNumber}
                sx={{ gridColumn: "span 2" }}
              />

               <TextField
                fullWidth
                type="text"
                label="Apellidos"
                onChange={event => setUserData({...userData, surnames: event.target.value})}
                value={userData.surnames}
                name="surnames"
                error={!!errors.surnames}
                helperText={errors.surnames}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                type="text"
                label="Nombre"
                onChange={event => setUserData({...userData, name: event.target.value})}
                value={userData.name}
                name="name"
                error={!!errors.name}
                helperText={errors.name}
                sx={{ gridColumn: "span 2" }}
              />
                <TextField
                fullWidth
                type="text"
                label="Centro de trabajo"
                onChange={event => setUserData({...userData, workState: event.target.value})}
                value={userData.workState}
                name="workState"
                error={!!errors.workState}
                helperText={errors.workState}
                sx={{ gridColumn: "span 4" }}
              />

              <TextField
                fullWidth
                type="text"
                label="Correo Electrónico"
                onChange={event => setUserData({...userData, email: event.target.value})}
                value={userData.email}
                name="email"
                error={!!errors.email}
                helperText={errors.email}
                sx={{ gridColumn: "span 4" }}
              />
              
              <FormControlLabel
                control={
                  <Checkbox 
                    checked={userData.concepts.ACS_U_PL_DESPLAZAM}
                    onChange={ev => setUserData({...userData, concepts: { ...userData.concepts, ACS_U_PL_DESPLAZAM: ev.target.checked}})} 
                    name="antoine" />
                }
                label="Plus desplazamiento"
              />
                  
              <FormControlLabel
                control={
                  <Checkbox 
                    checked={userData.concepts.ACS_U_MDIA_DIETA} 
                    onChange={ev => setUserData({...userData, concepts: { ...userData.concepts, ACS_U_MDIA_DIETA: ev.target.checked }})} 
                    name="antoine" />
                }
                label="Plus media dieta"
              />
                  
              <FormControlLabel
                control={
                  <Checkbox 
                    checked={userData.concepts.ACS_U_PLUS_AY_MANU} 
                    onChange={ev => setUserData({...userData, concepts: { ...userData.concepts, ACS_U_PLUS_AY_MANU: ev.target.checked }})} 
                    name="antoine" />
                }
                label="Plus ayuda manutención"
              />
                  


            </Box>
          </form>
    </Box>
);

export default UserForm;