import React from "react";
import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  useTheme,
} from "@mui/material";
import LogedLayout from "../../components/shared/LogedLayout";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import Header from "../../components/shared/Header";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import {
  GET_ESTIMATED_SIGNINGS_URL,
  GET_ALL_OTPS,
  GET_ALL_USERS,
  CREATE_ESTIMATION_SIGNING,
  DELETE_ESTIMATION_SIGNING
} from "../../common/constants.js";
import EnhancedTable from "../../components/shared/EnhancesTable";
import {
  Dropdown,
  DropdownMenuItem,
} from "../../components/shared/Dropdown.js";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { getDatFromColumn } from "../../common/utils";
import EnhancedToast from "../../components/shared/enhancesToast";
import SigningUpdate from "./estimatedSigningUpdate";
import { tokens } from "../../theme/theme";
import moment from "moment";
import EstimatedSigningCreate from "./EstimatedSigningCreate";
import EnhancedModal from "../../components/shared/EnhancedModal";

const SigningPage = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const axiosPrivate = useAxiosPrivate();
  const [data, setData] = React.useState({ loading: true });
  const [users, setUsers] = React.useState([]);
  const [otps, setOtps] = React.useState([]);
  const [editPanel, setEditPanel] = React.useState(false);
  const [deleteAction, setDeleteAction] = React.useState(false);
  const [open, setOpenAction] = React.useState(false);
  const [createPanel, setCreatePanel] = React.useState(false);

  const [toast, setToast] = React.useState({
    open: false,
    message: "",
    severity: "success",
  });
  const [filters, setFilters] = React.useState({
    user: -1,
    otp: -1,
  });
  const [options, setOptions] = React.useState({
    takeValue: 10,
    skipValue: 0,
    rowsPerPage: 10,
  });

  const SIGNING_COLUMN = [
    { field: "id", headerName: "ID", flex: 0.5 },
    {
      field: "date",
      headerName: "Fecha",
      flex: 1,
      cellClassName: "name-column--cell",
      renderCell: (params) => {
        const thisRow = getDatFromColumn(params);
        return moment(thisRow.date.date).format("DD/MM/YYYY");
      },
    },
    {
      field: "hours",
      headerName: "Nº de horas",
      flex: 1,
    },
    {
      field: "user",
      headerName: "Usuario",
      flex: 1,
      renderCell: (params) => {
        const thisRow = getDatFromColumn(params);
        return thisRow.user.user.name + " " + thisRow.user.user.surnames;
      },
    },
    {
      field: "otp",
      headerName: "O.T.",
      flex: 1,
      renderCell: (params) => {
        const thisRow = getDatFromColumn(params);
        return thisRow.otp.otp.name;
      },
    },
    {
      field: "description",
      headerName: "Descripción",
      flex: 1,
    },
    {
      field: "actions",
      headerName: "Acciones",
      flex: 1,
      renderCell: (params) => {
        const thisRow = getDatFromColumn(params).id;
        const onClickEdit = (e) => {
          setEditPanel(thisRow);
          e.stopPropagation(); // don't select this row after clicking
        };
        const onClickDeleteSigning = (e) => {
          setDeleteAction(thisRow);
          setOpenAction(true);
          e.stopPropagation(); // don't select this row after clicking
        };
        return (
          <Dropdown
            trigger={
              <IconButton>
                <MoreVertIcon sx={{ fontSize: "1.25rem" }} />
              </IconButton>
            }
            menu={[
              <DropdownMenuItem onClick={onClickEdit}>Editar</DropdownMenuItem>,
              <DropdownMenuItem onClick={onClickDeleteSigning}>
                Eliminar
              </DropdownMenuItem>,
            ]}
          />
        );
      },
    },
  ];

  const toogleDeleteSigning = async () => {
    const { id, userId, otpId, date, hours, concepts, description } =
      deleteAction;
    await axiosPrivate
      .post(
        DELETE_ESTIMATION_SIGNING,
        JSON.stringify({
          id,
          userId,
          otpId,
          date,
          hours,
          concepts,
          description,
        }),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      )
      .then(() => {
        setToast({
          open: true,
          message: "Imputación estimada eliminada con éxito",
          severity: "success",
        });
        getSignings();
      })
      .catch(() => {
        setToast({
          open: true,
          message: "Error eliminando imputación estimada, intentelo de nuevo más tarde.",
          severity: "error",
        });
        getSignings();
      });
    setOpenAction(false);
  };

  const getSignings = React.useCallback(async () => {
    const { takeValue, skipValue } = options;
    const response = await axiosPrivate.post(
      GET_ESTIMATED_SIGNINGS_URL,
      JSON.stringify({ takeValue, skipValue, ...filters }),
      {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      }
    );
    if (response) {
      setData({
        loading: false,
        data: response.data.data,
        totalRowCount: response.data.totalRowCount,
      });
    }
  }, [axiosPrivate, options, filters]);

  const createEstimationSigned = React.useCallback((async) => {
    setCreatePanel(true);
  });

  const getUsers = React.useCallback(async () => {
    const response = await axiosPrivate.get(GET_ALL_USERS);
    if (response.status === 201) {
      setUsers(response.data.data);
    }
  });

  const getOTPS = React.useCallback(async () => {
    const response = await axiosPrivate.get(GET_ALL_OTPS);
    if (response.status === 201) {
      setOtps(response.data.data);
    }
  });

  const handleUserFilterChange = (event) => {
    setFilters({ ...filters, user: event.target.value });
  };
  const handleOtpFilterChange = (event) => {
    setFilters({ ...filters, otp: event.target.value });
  };

  React.useEffect(() => {
    getSignings();
    getUsers();
    getOTPS();
  }, []);

  React.useMemo(() => {
    getSignings();
  }, [filters, options]);

  return (
    <LogedLayout>
      <Box m="20px">
        <Header
          title="Estimación Imputaciones"
          subtitle="Listado de estimación imputaciones"
          secondButtonText={"Añadir Estimación"}
          secondButtonAction={() => createEstimationSigned()}
        />
        <Grid container spacing={3}>
          <Grid item xs={2}>
            <FormControl
              fullWidth
              variant="standard"
              sx={{ m: 1, minWidth: 120 }}
            >
              <InputLabel id="demo-simple-select-standard-label">
                Usuarios
              </InputLabel>
              <Select value={filters.user} onChange={handleUserFilterChange}>
                <MenuItem value={-1}>Todos los usuarios</MenuItem>
                {users &&
                  users.map((user) => (
                    <MenuItem
                      key={user.id}
                      value={user.id}
                    >{`${user.name} ${user.surnames}`}</MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <FormControl
              fullWidth
              variant="standard"
              sx={{ m: 1, minWidth: 120 }}
            >
              <InputLabel id="demo-simple-select-standard-label">
                OTS
              </InputLabel>
              <Select value={filters.otp} onChange={handleOtpFilterChange}>
                <MenuItem value={-1}>Todas las OT</MenuItem>
                {otps &&
                  otps.map((otp) => (
                    <MenuItem
                      key={otp.id}
                      value={otp.id}
                    >{`${otp.name}`}</MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <EnhancedToast toast={toast} setToast={setToast} />

        {data && (
          <EnhancedTable
            data={data.data}
            options={options}
            setOptions={setOptions}
            header={SIGNING_COLUMN}
            checkboxSelection
            totalRow={data.totalRowCount}
            onRowClick={() => {}}
          />
        )}
      </Box>
      <SigningUpdate
        signing={editPanel}
        setSigning={setEditPanel}
        setToast={setToast}
        refetch={getSignings}
        otps={otps}
      />
      <EstimatedSigningCreate
        open={createPanel}
        setOpen={setCreatePanel}
        setToast={setToast}
        refetch={getSignings}
        otps={otps}
      />
      <EnhancedModal
        closeAction={() => setOpenAction(false)}
        acceptAction={() => toogleDeleteSigning()}
        closeButton={"Cancelar"}
        acceptButton={"Eliminar"}
        title={"Eliminar Imputación Estimada"}
        body={
          "Está acción eliminará la siguiente imputación estimada, ¿Quiere continuar?"
        }
        open={!!open}
      />
    </LogedLayout>
  );
};

export default SigningPage;
