import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { useTheme } from '@emotion/react';
import { tokens } from '../../theme/theme';
import { Box } from '@mui/system';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
});
const Panel = ({ open, requestClose, buttonAccept, acceptAction, title, bodyText, bodyStyle}) =>  {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
      <Dialog
        style={{
          width: '50%'
        }}
        fullScreen
        open={open}
        onClose={requestClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative', background: colors.blueAccent[700], fontSize: '1rem' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={requestClose}
              aria-label="close"
              style={{
                fontSize: '1rem',
                color: colors.grey[100], 
              }}
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1, fontSize: '1rem',   color: colors.grey[100], }} variant="h6" component="div">
              {title}
            </Typography>
            {buttonAccept &&
            <Button style={{ fontSize: '1rem',   color: colors.grey[100] }} autoFocus color="inherit" onClick={acceptAction}>
              {buttonAccept}
            </Button>
            }
          </Toolbar>
        </AppBar>
        <Box m="20px" style={{...bodyStyle}}>
        {bodyText}
        </Box>
      </Dialog>
  );
}

export default Panel;
