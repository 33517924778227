import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const EnhancedModal = ({ open, acceptAction, closeAction, closeButton, acceptButton, title, body }) =>  {


  return (
    <div>
      <Dialog open={!!open} onClose={closeAction}>
        <DialogTitle sx={{fontSize: '1.75rem'}}>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{fontSize: '1rem'}}>
           {body}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
            { closeButton && <Button color='warning' variant="contained" sx={{fontSize: '1rem'}} onClick={closeAction}>{closeButton}</Button>}
            { acceptButton && <Button color='info' variant="outlined" sx={{fontSize: '1rem'}} onClick={acceptAction}>{acceptButton}</Button>}
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default EnhancedModal;
