/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import RequireAuth from "./components/middleware/RequireAuth";
import { Routes, Route } from "react-router-dom";
import { ROLES } from "./common/constants";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme/theme.js";
import Dashboard from "./pages/dashboard/main.js";
import Login from "./pages/auth/Login.js";
import PrivacyPolicity from "./pages/legalText/privacyPolicity.js"
import LoadingSection from "./components/shared/LoadingSection";
import useAuth from "./hooks/useAuth";
import useRefreshToken from "./hooks/useRefreshToken";
import NotRequireAuth from "./components/middleware/NotRequireAuth";
import UsersPage from "./pages/users/main.js";
import Otps from "./pages/otps/index";
import SigningPage from "./pages/signings/SigningsPage";
import EstimatedSigningPage from "./pages/estimatedSignings/estimatedSigningsPage.js"
import ForgotPaswd from "./pages/auth/ForgotPaswd";
import ChangePasswd from "./pages/auth/ChangePasswd";
import DocumentationPage from "./pages/documentation/DocumentationPage";
import Cars from "./pages/cars/main.js";
import RegisterCars from "./pages/registerCars/main.js";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import { LocalizationProvider } from "@mui/x-date-pickers";


function App() {
  const [theme, colorMode] = useMode();
  const { auth } = useAuth();
  const refresh = useRefreshToken();
  const [dataAuth, setDataAuth] = React.useState({
    loading: true,
    data: null,
  });

  React.useMemo(async () => {
    if (Object.keys(auth).length === 0) {
      await refresh();
      setDataAuth({ loading: false, data: auth });
    } else {
      setTimeout(() => {
        setDataAuth({ loading: false, data: auth });
      }, 1000);
    }
  }, [auth]);

  if (dataAuth.loading) {
    return <LoadingSection />;
  }

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
      <LocalizationProvider
          dateAdapter={AdapterMoment}
          adapterLocale={moment.locale() || "es"}
        >
        <CssBaseline />
        <RouterWrapp />
        </LocalizationProvider>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

const RouterWrapp = () => (
  <Routes>
    {/* public routes */}   
    <Route path="/forgot-passwd" element={<ForgotPaswd />} />
    <Route path="/changePasswd/:token" element={<ChangePasswd />} />
    <Route element={<NotRequireAuth />}>
      <Route path="/login" element={<Login />} />
      <Route path="/privacyPolicity" element={<PrivacyPolicity />} />
    </Route>

    <Route element={<RequireAuth allowedRoles={[ROLES.WORKER, ROLES.ADMIN, ROLES.DEVADMIN]} />}>
      <Route path="/otps" element={<Otps />} />
      <Route path="/documentation" element={<DocumentationPage />} />
      <Route path="/" element={<Dashboard />} />
      <Route path="/users" element={<UsersPage />} />
      <Route path="/signing" element={<SigningPage />} />
      <Route path="/estimated/signing" element={<EstimatedSigningPage />} />
      <Route path="/cars" element={<Cars />} />
      <Route path="/cars/register" element={<RegisterCars />} />
    </Route>

    {/* catch all */}
  </Routes>
);

export default App;
