import React from 'react';
import DocumentationBody from './DocumentationBody';
import { Box } from '@mui/material';
import Header from '../../components/shared/Header';
import LogedLayout from '../../components/shared/LogedLayout';
import { saveAs } from 'file-saver'
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { DOWNLOAD_REPORT_PEOPLENET, DOWNLOAD_URL, GENERATE_FILE, GENERATE_REPORT_CAR_EXCEL, GET_ALL_CARS, GET_ALL_USERS, REGENERATE_PDF } from '../../common/constants';
import EnhancedToast from '../../components/shared/enhancesToast';
import { DOCUMENT_TYPES } from "../../common/constants"
import moment from 'moment';


const DocumentationPage = () => {
    const axiosPrivate = useAxiosPrivate();
    const [downloads, setDownloads ] = React.useState({ loading: true});
    const [ users, setUsers] = React.useState([]);
    const [cars, setCars] = React.useState([]);
    const [ isLoading, setIsLoading ] = React.useState(false);
  
    const getSourceDownloads = {
        REPORT_PEOPLENET: () => ({type: 'xlsx', path: DOWNLOAD_REPORT_PEOPLENET}),
        REPORT_WORKERS: () => ({type: 'xlsx', path: DOWNLOAD_REPORT_PEOPLENET}),
        REPORT_WORKER_DAY: () =>  ({type: 'pdf', path: GENERATE_FILE}),
        REPORT_TRACKER_VEHICLE: () => ({type: 'xlsx', path: GENERATE_REPORT_CAR_EXCEL})
    };
    
    const [filters, setFilters] = React.useState({
        userSelected: [],
        carSelected: -1,
        rangeDate:  {
            startDate: new Date(moment().endOf('day')),
            endDate: new Date(moment().startOf('day')),
          },
        documentType: DOCUMENT_TYPES.REPORT_PEOPLENET
    })

    const [toast, setToast] = React.useState({
        open: false,
        message: "",
        severity: "success",
    });
    const [options, setOptions] = React.useState({
        takeValue: 10,
        skipValue: 0,
        rowsPerPage: 10,
    });

    const getUsers = React.useCallback( async () => {
        const response = await axiosPrivate.get(GET_ALL_USERS);
        if(response.status === 201) {
          setUsers(response.data.data)
        }
      }, [ axiosPrivate ]);
      const getDownloads = React.useCallback(async () => {
        const { takeValue, skipValue } = options;
        const downloadResponse = await axiosPrivate.post(DOWNLOAD_URL,
            JSON.stringify({takeValue, skipValue}),
            {
                headers: { "Content-Type": "application/json" },
                withCredentials: true,
            }
        );
        if(downloadResponse.data){
            console.log(downloadResponse.data);
            setDownloads({ loading: false, data: downloadResponse.data.data, totalRowCount: downloadResponse.data.totalRowCount});
        }
    }, [ axiosPrivate, options]);



    const getCars =  React.useCallback( async () => {
        const carsResponses = await axiosPrivate.get(GET_ALL_CARS);
        if(carsResponses.status === 201) {
            setCars(carsResponses.data.data)
        }
    }, [axiosPrivate ]);



    const generateDocument = async ({ path }) => {
        const data = await axiosPrivate.post(
            path,
            JSON.stringify({ 
                users: filters.userSelected,
                dateRange: filters.rangeDate,
                carSelected: filters.carSelected
              }),
            {
              headers: { "Content-Type": "application/json" },
              withCredentials: true,
              responseType: 'arraybuffer'
            }
        );
        return data;
    }

    const downloadDocument = React.useCallback( async () => {
        const { type, path } = getSourceDownloads[filters.documentType]();
        const data = await generateDocument({ path });
        setIsLoading(false);
        console.log(data);
        if(data.status === 201){
            const blob = new Blob([data.data], { type: `application/${type}` })
            saveAs(blob, `gramalleira_${moment().valueOf()}.${type}`)
            getDownloads();
        }else if(data.status === 203){
            setToast({
                open: true,
                message: "No existen registros para generar el documento",
                severity: "error",
            })
        }else {
            setToast({
                open: true,
                message: "Error procesando la descarga",
                severity: "error",
            })
        }
    },[filters]);


    React.useEffect(() => {
      console.log('users', filters.rangeDate);
  }, [filters.rangeDate]);

    React.useEffect(() => {
        getDownloads();
        getUsers();
        getCars();
    }, [getDownloads]);

    return (
        <LogedLayout>
         <EnhancedToast toast={toast} setToast={setToast}/>
            <Box m="20px">
                <Header
                title="Documentación"
                subtitle="Listado de descargas"
                />
                <DocumentationBody 
                    options={options}
                    setToast={setToast}
                    setOptions={setOptions}
                    users={users}
                    cars={cars}
                    filters={filters}
                    setFilters={setFilters}
                    isLoading={isLoading}
                    refetch={getDownloads}
                    downloads={downloads}
                    downloadDocument={downloadDocument}
                />
            </Box> 
        </LogedLayout>
    )
}


export default DocumentationPage