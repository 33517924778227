import { useRef, useState, useEffect } from "react";
import axios from "../../api/axios.js";
import { FORGOT_PASSWD } from "../../common/constants.js";
import NotLoggedLayout from "../../components/shared/NotLoggedLayout.js";
import {
  Avatar,
  Box,
  Button,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

import forgotPasswd from '../../assets/forgotPasswd.svg';
import { useTheme } from "@emotion/react";
import { tokens } from "../../theme/theme.js";

const ForgotPaswd = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const errRef = useRef();

  const [email, setEmail] = useState("");
  const [message, setMessage] = useState({
    type: "",
    text: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await axios.post(
        FORGOT_PASSWD,
        JSON.stringify({ email }),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );
      setMessage({
        type: "success",
        text: "Si este email existe en la base de datos, debería recibir un correo electronico con más instrucciones",
      });
    } catch (err) {
      setMessage({
        type: "error",
        text: "Intentelo de nuevo o más tarde",
      });
      errRef.current.focus();
    }
    setEmail('');
  };

  return (
    <NotLoggedLayout>
      <Grid container component="main" sx={{ height: "100%" }}>
      <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: `url(${forgotPasswd})`,
            backgroundRepeat: "no-repeat",
            backgroundColor: colors.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <Grid item xs={12} sm={8} md={5}  elevation={6} square='true'>  
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
           <Avatar sx={{ m: 1, bgcolor: message.type !== 'error' ? "secondary.main": 'error.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            <div
              ref={errRef}
              className={message.type === 'error'  ? "errmsg" : "offscreen"}
              aria-live="assertive"
            >
            <Typography component="h1" variant="h4" color={message.type === 'error' ? 'error.main' : 'secondary.main'} style={{ textAlign: 'center', marginBottom: '0.5rems'}}>
              {message.text}
          </Typography>
          </div>
            {message.type !== 'success' && 
            <>
            <Typography component="h1" variant="h3">
              Restablecer Contraseña
            </Typography>
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{ mt: 1, width: '90%' }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Correo Electrónico"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                name="email"
                autoComplete="email"
                autoFocus
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2, fontSize: '1.25rem' }}
              >
                Restablecer Contraseña
              </Button>
              <Grid container>
                <Grid item xs>
                <a href="/signing" style={{ fontSize: '1.05rem', color: '#3880FF'}}>
                    Iniciar sesión
                  </a>
                </Grid>
              </Grid>
            </Box>
            </>
          }
          </Box>
        </Grid>
      </Grid>
    </NotLoggedLayout>
  );
};

export default ForgotPaswd;
