import React from 'react';
import { CARS_CREATE, GET_ALL_USERS, INITIAL_CAR_VALUE, INITIAL_CAR_VALUE_ERROR } from '../../common/constants';
import { CheckCarRequiredFields } from '../../common/utils';
import Panel from '../../components/shared/Panel';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import CarForm from './CarForm';


const CarCreate = ({openPanel, closePanel, setToast, refetch}) => {
    const axiosPrivate = useAxiosPrivate();
    const [errors, serErrors] = React.useState(INITIAL_CAR_VALUE_ERROR);
    const [carsMapper, setCarsMapper] = React.useState(INITIAL_CAR_VALUE);
    const [users, setUsers] = React.useState([]);


    const getUsers = React.useCallback( async () => {
        const response = await axiosPrivate.get(GET_ALL_USERS);
        if(response.status === 201) {
          setUsers(response.data.data)
        }
    });

    const createUser = async () => {
        const checkErrors = CheckCarRequiredFields(carsMapper);
        if(checkErrors.errorCounter === 0){
            await axiosPrivate.post(
                CARS_CREATE,
                JSON.stringify({ carsMapper }),
                {
                    headers: { "Content-Type": "application/json" },
                    withCredentials: true,
                }
            ).then(() => {
                refetch();
                setCarsMapper(INITIAL_CAR_VALUE)
                setToast({
                    open: true,
                    message: "Vehículo creado con éxito",
                    severity: "success",
                })   
            })
            .catch(() => {
                setToast({
                    open: true,
                    message: "Error creando el vehículo, intentelo de nuevo.",
                    severity: "error",
                });        
            });
            closePanel(false);
            serErrors(INITIAL_CAR_VALUE_ERROR); 
            
        }else{
            console.log('errors', errors);
            setToast({
                open: true,
                message: "Revisa los datos del formulario.",
                severity: "error",
            }); 
            serErrors(checkErrors.userErrors);
        }
    }

    React.useEffect(() => {
        if(openPanel){
            getUsers();
        }
    },[openPanel]);

    return (
        <Panel
        title={"Añadir vehículo"}
        open={openPanel}
        bodyStyle={{ overflow: "hidden" }}
        bodyText={<CarForm carData={carsMapper} users={users} setCarData={setCarsMapper} errors={errors}/>}
        buttonAccept={"aceptar"}
        acceptAction={() => createUser()}
        requestClose={() => {serErrors(INITIAL_CAR_VALUE_ERROR); setCarsMapper(INITIAL_CAR_VALUE); closePanel(false); } }
      />
    )
};

export default CarCreate;