import { 
    Box,
    TextField,
    FormControl,
    InputLabel,
    Select, 
    MenuItem
} from "@mui/material";
import moment from "moment";

const RegisterCarForm = ({
    registerCarData,
    setRegisterCarData,
    errors={},
    users=[],
    cars=[]
}) => (
    <Box m="20px">
          <form>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            >
              <TextField
                fullWidth
                type="date"
                label="Fecha"
                value={moment(registerCarData?.date).format('YYYY-MM-DD')}
                onChange={event => setRegisterCarData({...registerCarData, date: event.target.value})}
                name="date"
                error={!!errors.date}
                helperText={errors.date}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                type="number"
                label="Kilometros"
                onChange={event => setRegisterCarData({...registerCarData, total_kilometers: event.target.value.trim()})}
                value={registerCarData?.total_kilometers}
                name="total_kilometers"
                error={!!errors.total_kilometers}
                helperText={errors.total_kilometers}
                sx={{ gridColumn: "span 2" }}
              />
             <FormControl fullWidth  sx={{ gridColumn: 'span 2'}}>
                <InputLabel id="selectuusers">Usuario Responsable</InputLabel>
                <Select
                    label='Usuario Responsable'
                    helperText={errors.userId}
                    value={registerCarData?.userId}
                    onChange={event => setRegisterCarData({...registerCarData, userId: event.target.value})}
                    >
                    {users.length > 0 && users.map(user => (
                        <MenuItem key={user.id} value={user.id} >{`${user.name}`} {`${user.surnames}`}</MenuItem>
                        ))} 
                </Select>
               </FormControl >
               <FormControl fullWidth  sx={{ gridColumn: 'span 2'}}>
                <InputLabel id="selectuusers">Vehículo</InputLabel>
                <Select
                    label='Vehículo'
                    helperText={errors.carId}
                    value={registerCarData?.carId}
                    onChange={event => setRegisterCarData({...registerCarData, carId: event.target.value})}
                    >
                    {cars.length > 0 && cars.map(car => (
                        <MenuItem key={car.id} value={car.id} >{`${car.license_plate} - ${car.brand} ${car.model}`}</MenuItem>
                        ))} 
                </Select>
               </FormControl >
               <TextField
                fullWidth
                type="text"
                label="Reporte"
                onChange={event => setRegisterCarData({...registerCarData, report: event.target.value})}
                value={registerCarData?.report}
                name="report"
                error={!!errors.report}
                helperText={errors.report}
                sx={{ gridColumn: "span 4" }}
              />

            </Box>
          </form>
    </Box>
);

export default RegisterCarForm;